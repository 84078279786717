import React, { Component } from 'react';

import { SALES_EXPERIENCE_QUESTIONS } from 'pages/CandidatePortal/OnboardingPages/constants';

import { HIRING_STATUS_SOURCE } from 'connectors/Defaults/constants';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Block from 'components/Block';
import Input from 'components/Input';
import InputRadio from 'components/InputRadio';
import InputAutoComplete from 'components/InputAutoComplete';
import InputCheckboxMany from 'components/InputCheckboxMany';
import Label from 'components/Label';
import Tag from 'components/Tag';

import CandReqInputs from './components/CandReqInputs';

import styles from './CandidateEditorSkills.scss';

class CandidateEditorSkills extends Component {
  makeCandReqInputs = ({ candReq, blockProps }) => {
    const {
      handleCandReqInputChange,
      handleCandReqRoleChange,
      handleCandReqWorkModelChange,
      handleToggleArray,
      handleToggleRegions,
      pickerOptions = {},
    } = this.props;

    const candReqInputsProps = {
      candReq: candReq || {},
      handleToggleArray,
      handleToggleRegions,
      handleCandReqInputChange,
      handleCandReqRoleChange,
      handleCandReqWorkModelChange,
      pickerOptions,
    };

    return (
      <Block {...blockProps}>
        <CandReqInputs {...candReqInputsProps} />
      </Block>
    );
  };

  render() {
    const {
      candidate: {
        attributes,
        attributes: { hiringStatus, qAndA = {} } = {},
        id: candidateId,
        relationships: { candidateRequirement: { data: candidateRequirementIds = [] } = {} } = {},
      } = {},
      candidateRequirements: { byId: candidateRequirementsById = {} } = {},
      handleCandidateInputChange,
      handleQAndATextInputChange,
      handleQAndAToggleArrayValue,
      saving,
      workHistories: { allIds: workHistoriesAllIds = [], byId: workHistoriesById = {} } = {},
    } = this.props;

    const savingContent = saving ? (
      <div className={styles.autoSaving}>Saving...</div>
    ) : (
      <div className={styles.autoSaved}>Saved</div>
    );

    const saveContent = saving === undefined ? null : savingContent;

    const { multiAnswer = [], singleAnswer = [] } = qAndA || {};

    const workHistoryIds = workHistoriesAllIds.filter(
      (id) => workHistoriesById[id].attributes.candidateId === candidateId
    );

    const workHistories = workHistoryIds.map((id) => workHistoriesById[id].attributes || {});

    const { [candidateRequirementIds[0]]: { attributes: candReq = {} } = {} } =
      candidateRequirementsById || {};

    const { role: recentRole = null } = workHistories[0] || {};

    const qAndABlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: false,
      className: styles.qAndABlock,
      title: 'Q & A',
    };

    const commonQAndAProps = {
      type: 'textarea',
      size: 'xxlarge',
      topLabel: true,
      rows: '5',
    };

    const singleAnswerInputs = singleAnswer.map((questionObj, idx) => {
      const props = {
        ...commonQAndAProps,
        handleInputChange: (event) => handleQAndATextInputChange({ event, idx }),
        name: questionObj.question,
        label: questionObj.question,
        value: questionObj.answer || '',
        placeholder: '',
      };

      return <Input key={idx} {...props} />;
    });

    const multiAnswerInputs = multiAnswer.map((questionObj, idx) => {
      if (questionObj.question === 'Are you receiving additional incentives?') {
        const source = ['Stock Options', 'Equity', 'Gym Membership', 'Company Car', 'Other'];

        const props = {
          label: questionObj.question,
          source,
          name: questionObj.question,
          handleInputChange: (event) => handleQAndAToggleArrayValue({ event, idx }),
          selectedValues: questionObj.answer || [],
          key: idx,
        };

        return <InputCheckboxMany {...props} />;
      }
      if (questionObj.question === 'Awards and honors') {
        const source = ["President's Club", 'Top 1% Club'];

        const filteredSource = source.filter((option) => {
          if (questionObj.answer) {
            return !questionObj.answer.includes(option);
          }
          return true;
        });

        const props = {
          characterLimit: 70,
          label: questionObj.question,
          source: filteredSource,
          name: questionObj.question,
          handleInputChange: (event) => handleQAndAToggleArrayValue({ event, idx }),
          placeholder: '',
          size: 'full',
          topLabel: true,
          hasAddButton: true,
        };

        const hasAnswers = questionObj.answer !== null && questionObj.answer !== undefined;

        const answersSelected = hasAnswers
          ? questionObj.answer.map((answer) => {
              const tagProps = {
                name: answer,
                inputName: questionObj.question,
                grayTag: true,
                onDeleteClick: (name, inputName) =>
                  handleQAndAToggleArrayValue({
                    event: {
                      target: {
                        value: name,
                        name: inputName,
                      },
                    },
                    idx,
                  }),
              };

              return <Tag key={answer} {...tagProps} />;
            })
          : null;

        return (
          <div key={questionObj.question} className={styles.aggroQuestionContainer}>
            <InputAutoComplete {...props} />
            <div className={styles.aggroAnswerContainer}>{answersSelected}</div>
          </div>
        );
      }
      return null;
    });

    const hiringStatusInputProps = {
      required: true,
      source: HIRING_STATUS_SOURCE,
      name: 'hiringStatus',
      handleInputChange: handleCandidateInputChange,
      selectedValues: hiringStatus !== '' ? [hiringStatus] : [],
      blockStyle: true,
      flexFit: true,
      hasLabel: true,
    };

    const questions = recentRole
      ? SALES_EXPERIENCE_QUESTIONS[recentRole] || SALES_EXPERIENCE_QUESTIONS.default
      : SALES_EXPERIENCE_QUESTIONS['Non-Sales'];

    const questionsContent = questions
      ? questions.map(({ camelKey, question, options }) => {
          const props = {
            key: camelKey,
            options,
            name: camelKey,
            value: attributes[camelKey],
            handleInputChange: handleCandidateInputChange,
          };

          return (
            <React.Fragment key={question}>
              <Label required className={styles.label}>
                {question}
              </Label>
              <InputRadio {...props} />
            </React.Fragment>
          );
        })
      : null;

    const qAndAInputContent = (
      <Block {...qAndABlockProps}>
        <div className={styles.basicInfoContainer}>
          <Label standalone required>
            Where are you in your job search?
          </Label>
          <InputCheckboxMany {...hiringStatusInputProps} />

          {questionsContent}

          {singleAnswerInputs}

          {multiAnswerInputs}
        </div>
      </Block>
    );

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const skillsBlockProps = {
      noTitlePadding: true,
      largeTitleFont: true,
    };

    const blockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: false,
      className: styles.candReqBlock,
      title: 'Your Next Role',
    };

    const candReqInputContent = this.makeCandReqInputs({ candReq, blockProps });

    return (
      <LayoutWithoutSidebar
        content={
          <div className={styles.EditorSkills}>
            <Block {...titleBlockProps}>
              <div className={styles.titleContainer}>
                <div className={styles.title}>Recruiting Report</div>
                <div className={styles.saveContent}>{saveContent}</div>
              </div>
            </Block>
            <div className={styles.scrollDiv}>
              <Block {...skillsBlockProps}>
                <div className={styles.candReqInputContainer}>{candReqInputContent}</div>
                <div className={styles.qAndAInputContainer}>{qAndAInputContent}</div>
              </Block>
            </div>
          </div>
        }
      />
    );
  }
}

export default CandidateEditorSkills;
