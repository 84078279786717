import React from 'react';
import InputSelectMany from 'components/InputSelectMany';
import Tag from 'components/Tag';
import { SelectedRate } from 'components/SelectedRate';

import styles from './InputSelectManyValues.scss';

function InputSelectManyValues({
  source,
  size,
  name,
  label,
  handleInputChange,
  selectedValues,
  defaultIcon,
  defaultLabel,
  required,
  autoFocus,
  highlight,
  values,
  onDeleteClick,
  maxSelected = null,
  ...rest
}) {
  const tags = values
    ? values.map((ind) => (
        <Tag key={ind} name={ind} inputName={name} onDeleteClick={onDeleteClick} />
      ))
    : null;

  const selectedRate =
    maxSelected === null ? '' : ` (${(selectedValues || []).length}/${maxSelected})`;

  return (
    <div {...rest}>
      <InputSelectMany
        source={source}
        size={size}
        name={name}
        label={label}
        handleInputChange={handleInputChange}
        selectedValues={selectedValues}
        maxSelected={maxSelected}
        defaultIcon={defaultIcon}
        defaultLabel={defaultLabel}
        required={required}
        autoFocus={autoFocus}
        highlight={highlight}
      />
      <SelectedRate selectedRate={selectedRate} />
      <div className={styles.autocompleteContainer}>{tags}</div>
    </div>
  );
}

export default InputSelectManyValues;
