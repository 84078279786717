import { handleError } from 'utils/common';
import { getNestedEntity } from 'api/apiEntity';

const promiseGetRehostChecklist = ({ candidateId }) => {
  const onFulfilled = ({ data: { rehostChecklist } }) => rehostChecklist;

  const onFailed = (error) => {
    handleError(error);

    return [];
  };

  return getNestedEntity({
    id: candidateId,
    type: 'candidates',
    nestedType: 'rehost_checklist',
    config: {
      params: {
        camelizeIt: true,
      },
    },
  })
    .then(onFulfilled)
    .catch(onFailed);
};

export default promiseGetRehostChecklist;
