import { handleActions } from 'redux-actions';

import {
  PROFILE_CARDS_LOAD_DONE,
  PROFILE_CARDS_LOAD_STARTED,
  BATCH_REQUEST_LOAD_DONE,
  BATCH_REQUEST_LOAD_STARTED,
} from '../actions';

const initialState = {
  profileCards: [],
  profileCardsLoading: true,
  batchRequest: {},
  batchRequestLoading: true,
};

const handleProfileCardsLoadDone = (state = initialState, action) => {
  const {
    payload: { profileCards, rehostChecklist },
  } = action;

  return {
    ...state,
    ...(profileCards ? { profileCards } : {}),
    ...(rehostChecklist ? { rehostChecklist } : {}),
    profileCardsLoading: false,
  };
};

const handleProfileCardsLoadStarted = (state = initialState, action) => {
  const { payload } = action;
  const { noLoader = false } = payload || {};

  return {
    ...state,
    profileCardsLoading: !noLoader,
  };
};

const handleBatchRequestLoadDone = (state = initialState, action) => {
  const {
    payload: { batchRequest },
  } = action;

  return {
    ...state,
    batchRequest,
    batchRequestLoading: false,
  };
};

const handleBatchRequestLoadStarted = (state = initialState) => ({
  ...state,
  batchRequestLoading: true,
});

const actionHandlers = {
  [PROFILE_CARDS_LOAD_DONE]: handleProfileCardsLoadDone,
  [PROFILE_CARDS_LOAD_STARTED]: handleProfileCardsLoadStarted,
  [BATCH_REQUEST_LOAD_DONE]: handleBatchRequestLoadDone,
  [BATCH_REQUEST_LOAD_STARTED]: handleBatchRequestLoadStarted,
};

const reduceCandidateHome = handleActions(actionHandlers, initialState);

export default reduceCandidateHome;
