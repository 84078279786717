import { createSelector } from 'reselect';
import { getApp } from 'shared/store/app/selectors';

const selectCandidateHome = createSelector(getApp, (state) => state.candidateHome);

const selectRehostChecklist = createSelector(
  selectCandidateHome,
  (candidateHome = {}) => candidateHome.rehostChecklist
);

export default selectRehostChecklist;
