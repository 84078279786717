import { promisePickerOptions } from 'connectors/Defaults/promises';

export const accountExecutiveFiltersData = [
  ['Closing experience', 'filter[closing_experience_months][]=gt:0'],
  ['Outside sales exp.', 'filter[outside_sales_experience][]=true'],
  ['Sold to Clevel', 'filter[sold_c_level][]=true'],
  ['Sold SaaS', 'filter[sold_saas][]=true'],
  ['Startup experience', 'filter[startup_experience][]=true'],
  ['Management exp.', 'filter[management_experience][]=true'],
];

export default [
  {
    id: 'roleae',
    sideFilters: [
      {
        id: 'location',
        type: 'location',
        label: 'Location',
      },
      {
        id: 'distance',
        type: 'distance',
        label: 'Distance',
      },
      {
        id: 'ote-range',
        type: 'money-range',
        label: 'OTE Range',
        fromName: 'oteCentsFrom',
        toName: 'oteCentsTo',
        fromDataKey: 'oteCentsFrom',
        toDataKey: 'oteCentsTo',
      },
      {
        id: 'salary-range',
        type: 'money-range',
        label: 'Base Salary Range',
        fromName: 'salaryCentsFrom',
        toName: 'salaryCentsTo',
        fromDataKey: 'salaryCentsFrom',
        toDataKey: 'salaryCentsTo',
      },
      {
        id: 'additional-filters',
        type: 'dropdown',
        label: 'Additional Filters',
        name: 'accountExecutiveFilters',
        dataKey: 'accountExecutiveFilters',
        getOptions: () => Promise.resolve(accountExecutiveFiltersData.map(([key, _]) => key)),
      },
      {
        id: 'industries',
        type: 'dropdown',
        label: 'Industries sold to?',
        name: 'soldToIndustries',
        dataKey: 'soldToIndustries',
        getOptions: () => promisePickerOptions().then(({ industry }) => industry),
      },
      {
        id: 'department',
        type: 'dropdown',
        label: 'Departments sold to?',
        name: 'soldToDepartments',
        dataKey: 'soldToDepartments',
        getOptions: () => promisePickerOptions().then(({ department }) => department),
      },
      {
        id: 'deals-average-cents-range',
        type: 'money-range',
        label: 'Ideal Deal Size',
        fromName: 'dealsAverageCentsFrom',
        toName: 'dealsAverageCentsTo',
        fromDataKey: 'dealsAverageCentsFrom',
        toDataKey: 'dealsAverageCentsTo',
      },
      {
        id: 'quota-amount-cents-range',
        type: 'money-range',
        label: 'Ideal Quota',
        fromName: 'quotaAmountCentsFrom',
        toName: 'quotaAmountCentsTo',
        fromDataKey: 'quotaAmountCentsFrom',
        toDataKey: 'quotaAmountCentsTo',
      },
      {
        id: 'keywords',
        type: 'keywords',
        name: 'keywords',
        label: 'Keyword(s)',
        dataKey: 'keywords',
        filterName: 'sdrfilterkeywords',
        placeholder: "Try 'Saas' or 'CRM'",
      },
    ],
  },
  {
    id: 'rolesdr',
    sideFilters: [
      {
        id: 'location',
        type: 'location',
        label: 'Location',
      },
      {
        id: 'distance',
        type: 'distance',
        label: 'Distance',
      },
      {
        id: 'ote-range',
        type: 'money-range',
        label: 'OTE Range',
        fromName: 'oteCentsFrom',
        toName: 'oteCentsTo',
        fromDataKey: 'oteCentsFrom',
        toDataKey: 'oteCentsTo',
      },
      {
        id: 'salary-range',
        type: 'money-range',
        label: 'Base Salary Range',
        fromName: 'salaryCentsFrom',
        toName: 'salaryCentsTo',
        fromDataKey: 'salaryCentsFrom',
        toDataKey: 'salaryCentsTo',
      },
      {
        id: 'additional-filters',
        type: 'dropdown',
        label: 'Additional Filters',
        name: 'accountExecutiveFilters',
        dataKey: 'accountExecutiveFilters',
        getOptions: () => Promise.resolve(accountExecutiveFiltersData.map(([key, _]) => key)),
      },
      {
        id: 'industries',
        type: 'dropdown',
        label: 'Industries sold to?',
        name: 'soldToIndustries',
        dataKey: 'soldToIndustries',
        getOptions: () => promisePickerOptions().then(({ industry }) => industry),
      },
      {
        id: 'department',
        type: 'dropdown',
        label: 'Departments sold to?',
        name: 'soldToDepartments',
        dataKey: 'soldToDepartments',
        getOptions: () => promisePickerOptions().then(({ department }) => department),
      },
      {
        id: 'keywords',
        type: 'keywords',
        name: 'keywords',
        label: 'Keyword(s)',
        dataKey: 'keywords',
        filterName: 'sdrfilterkeywords',
        placeholder: "Try 'Saas' or 'CRM'",
      },
    ],
  },
];
