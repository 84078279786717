import { handleError } from 'utils/common';
import { patchNestedEntity } from 'api/apiEntity';

const promisePatchRehostChecklist = ({
  candidateId,
  resumeUpdated,
  workHistoryUpdated,
  personalInfoUpdated,
  recruitingReportUpdated,
}) => {
  const onFulfilled = ({ data: { rehostChecklist } }) => rehostChecklist;

  const onFailed = (error) => {
    handleError(error);

    return [];
  };

  return patchNestedEntity({
    id: candidateId,
    type: 'candidates',
    nestedType: 'rehost_checklist',
    data: {
      type: 'rehost_checklist',
      attributes: {
        ...(typeof resumeUpdated === 'boolean' ? { resume_updated: resumeUpdated } : {}),
        ...(typeof workHistoryUpdated === 'boolean'
          ? { work_history_updated: workHistoryUpdated }
          : {}),
        ...(typeof personalInfoUpdated === 'boolean'
          ? { personal_info_updated: personalInfoUpdated }
          : {}),
        ...(typeof recruitingReportUpdated === 'boolean'
          ? { recruiting_report_updated: recruitingReportUpdated }
          : {}),
      },
    },
    config: {
      params: {
        camelizeIt: true,
      },
    },
  })
    .then(onFulfilled)
    .catch(onFailed);
};

export default promisePatchRehostChecklist;
