import React, { Component } from 'react';
import classnames from 'classnames';

import { HIRING_STATUS_SOURCE } from 'connectors/Defaults/constants';

import Bold from 'components/Bold';
import Bolder from 'components/Bolder';
import Content from 'components/Content';
import FontIcon from 'components/FontIcon';
import InputCheckboxMany from 'components/InputCheckboxMany';
import TableOverlay from 'components/TableOverlay';
import InputCheckbox from 'components/InputCheckbox';

import { promisePatchRehostChecklist } from '../../promises';

import ProfileCard from './components/ProfileCard';
import ChecklistIcon from './components/ChecklistIcon';
import GetListed from './components/GetListed';

import styles from './Checklist.scss';

const STATUS_STRINGS = {
  inactive: [
    <Content key="inactivePart1">
      You&apos;re marked as <Bolder>&ldquo;Not looking&rdquo;</Bolder>, which means you&apos;ll miss
      potential opportunities for cool jobs.
    </Content>,
    <Content key="inactivePart2" className={styles.padded}>
      If you&apos;d like to change this, select <Bolder>&ldquo;Actively interviewing&rdquo;</Bolder>{' '}
      or <Bolder>&ldquo;Just browsing&rdquo;</Bolder> from the options below.
    </Content>,
  ],
  active: [
    <Content key="activePart1">
      You&apos;re marked as <Bolder>&ldquo;Actively interviewing&rdquo;</Bolder>, which means
      recruiters can potentially see your profile and reach out to you with opportunities.
    </Content>,
  ],
  passive: [
    <Content key="passivePart1">
      You&apos;re marked as <Bolder>&ldquo;Just browsing&rdquo;</Bolder>, which means recruiters can
      potentially see your profile and reach out to you with opportunities.
    </Content>,
  ],
};

class Checklist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOne: false,
      showTwo: false,
      showThree: false,
      showFour: false,
    };
  }

  componentDidMount() {
    const { handleCandidateInputChange, candidate } = this.props;

    const { approvedViewSeen = true, applicationStatus: appStatus } = candidate || {};

    if (appStatus === 'approved' && !approvedViewSeen) {
      handleCandidateInputChange({ target: { name: 'approvedViewSeen', value: true } });
    }
  }

  updateRehostChecklist = ({
    complete,
    workHistoryUpdated,
    resumeUpdated,
    personalInfoUpdated,
    recruitingReportUpdated,
  }) => {
    const {
      actions: { profileCardsLoadDone },
    } = this.props;

    profileCardsLoadDone({
      rehostChecklist: {
        complete,
        workHistoryUpdated,
        resumeUpdated,
        personalInfoUpdated,
        recruitingReportUpdated,
      },
    });
  };

  delay = (rehostChecklist) => {
    const { workHistoryUpdated, resumeUpdated, personalInfoUpdated, recruitingReportUpdated } =
      rehostChecklist;

    if (
      workHistoryUpdated === true &&
      resumeUpdated === true &&
      personalInfoUpdated === true &&
      recruitingReportUpdated === true
    ) {
      return new Promise((res) => setTimeout(() => res(rehostChecklist), 2000));
    }

    return rehostChecklist;
  };

  render() {
    const {
      candidate,
      candidateProfilePatchDone,
      handleBatchRequest,
      handleBatchRerequest,
      handleCandidateInputChange,
      setBackLink,
      profileCards = [],
      profileCardsLoading,
      rehostChecklist,
      batchRequest,
      batchRequestLoading,
    } = this.props;

    const {
      complete,
      resumeUpdated,
      workHistoryUpdated,
      personalInfoUpdated,
      recruitingReportUpdated,
    } = rehostChecklist;

    const { id: candidateId } = candidate || {};

    console.log(
      complete,
      resumeUpdated,
      workHistoryUpdated,
      personalInfoUpdated,
      recruitingReportUpdated
    );

    const rehostView = complete === false;

    const rehostProfileCards = [
      {
        id: 'upload-updated-resume',
        profile_card_id: 'candidate_fields_resume',
        optional: false,
        onClick: () => {
          promisePatchRehostChecklist({ candidateId, resumeUpdated: true }).then(
            this.updateRehostChecklist
          );
        },
        card: {
          link: '/candidate/edit-personal?model=candidate&fields[]=resume',
          header: 'Upload updated resume',
          message: 'Upload updated resume',
          checkbox: (
            <div className={styles.checkboxSubheader}>
              <InputCheckbox
                label="My resume is up to date"
                checked={resumeUpdated}
                handleInputChange={({ target: { checked } }) => {
                  this.updateRehostChecklist({
                    complete: false,
                    workHistoryUpdated,
                    personalInfoUpdated,
                    recruitingReportUpdated,
                    resumeUpdated: checked,
                  });

                  promisePatchRehostChecklist({ candidateId, resumeUpdated: checked })
                    .then(this.delay)
                    .then(this.updateRehostChecklist);
                }}
              />
            </div>
          ),
        },
        complete: false,
        priority: 1,
      },
      {
        id: 'update-my-workhistory-data',
        profile_card_id: 'work_history_presence',
        optional: true,
        onClick: () => {
          promisePatchRehostChecklist({ candidateId, workHistoryUpdated: true }).then(
            this.updateRehostChecklist
          );
        },
        card: {
          // link: '/candidate/edit-work?model=work_history&model_index=-1',
          link: '/candidate/edit-work?model=work_history',
          header: 'Update my Work History data',
          message: 'Update my Work History data',
          checkbox: (
            <div className={styles.checkboxSubheader}>
              <InputCheckbox
                label="My work history data is up to date"
                checked={workHistoryUpdated}
                handleInputChange={({ target: { checked } }) => {
                  this.updateRehostChecklist({
                    complete: false,
                    workHistoryUpdated: checked,
                    resumeUpdated,
                    personalInfoUpdated,
                    recruitingReportUpdated,
                  });

                  promisePatchRehostChecklist({ candidateId, workHistoryUpdated: checked })
                    .then(this.delay)
                    .then(this.updateRehostChecklist);
                }}
              />
            </div>
          ),
        },
        complete: false,
        priority: 2,
      },
      {
        id: 'update-my-recruiting-report',
        profile_card_id: 'recruiting_report',
        optional: true,
        onClick: () => {
          promisePatchRehostChecklist({ candidateId, recruitingReportUpdated: true }).then(
            this.updateRehostChecklist
          );
        },
        card: {
          link: '/candidate/edit-recruiting?model=recruiting_report',
          header: 'Update my Recruiting Report',
          message: 'Update my Recruiting Report',
          checkbox: (
            <div className={styles.checkboxSubheader}>
              <InputCheckbox
                label="My recruiting report is up to date"
                checked={recruitingReportUpdated}
                handleInputChange={({ target: { checked } }) => {
                  this.updateRehostChecklist({
                    complete: false,
                    recruitingReportUpdated: checked,
                    resumeUpdated,
                    workHistoryUpdated,
                    personalInfoUpdated,
                  });

                  promisePatchRehostChecklist({ candidateId, recruitingReportUpdated: checked })
                    .then(this.delay)
                    .then(this.updateRehostChecklist);
                }}
              />
            </div>
          ),
        },
        complete: false,
        priority: 2,
      },
      {
        id: 'update-my-personal-info',
        profile_card_id: 'personal_info',
        optional: true,
        onClick: () => {
          promisePatchRehostChecklist({ candidateId, personalInfoUpdated: true }).then(
            this.updateRehostChecklist
          );
        },
        card: {
          link: '/candidate/edit-personal?model=personal_info',
          header: 'Update my Personal Info',
          message: 'Update my Personal Info',
          checkbox: (
            <div className={styles.checkboxSubheader}>
              <InputCheckbox
                label="My personal info is up to date"
                checked={personalInfoUpdated}
                handleInputChange={({ target: { checked } }) => {
                  this.updateRehostChecklist({
                    complete: false,
                    workHistoryUpdated,
                    resumeUpdated,
                    recruitingReportUpdated,
                    personalInfoUpdated: checked,
                  });

                  promisePatchRehostChecklist({ candidateId, personalInfoUpdated: checked })
                    .then(this.delay)
                    .then(this.updateRehostChecklist);
                }}
              />
            </div>
          ),
        },
        complete: false,
        priority: 2,
      },
    ];

    const { showOne, showTwo, showThree, showFour } = this.state;

    const {
      hiringStatus,
      applicationStatus: appStatus,
      profileLocked = false,
      showProfileCards,
    } = candidate || {};

    const hasCards = !profileCardsLoading && profileCards && profileCards.length > 0;

    const allOptional = hasCards && profileCards.every((card) => card.optional);

    const hasProfileCards = hasCards && !profileLocked;

    const setBackLinkToHome = () =>
      setBackLink({
        link: '/candidate/home',
        title: 'Back to checklist',
      });

    const profCards = hasProfileCards
      ? profileCards.map((profileCard) => (
          <ProfileCard
            key={profileCard.id}
            {...profileCard}
            setBackLinkToHome={setBackLinkToHome}
          />
        ))
      : null;

    const profileCardsInfo =
      hasProfileCards && !allOptional ? (
        <Content className={styles.secondary}>
          You have incomplete items in your profile. You&apos;ll need to finish these tasks before
          you can be listed on the platform.
        </Content>
      ) : null;

    const profileCardsOptionalInfo =
      hasProfileCards && allOptional ? (
        <Content className={styles.secondary}>
          Your profile is almost complete! There are a few more things you can do to make yourself
          stand out from the crowd!
        </Content>
      ) : null;

    const profileCardsRehostInfo = (
      <Content className={styles.secondary}>
        It looks like we haven&apos;t seen you in a while. Please make sure your information is up
        to date before being listed on the platform.
      </Content>
    );

    const profileCardsCopy = profileCardsInfo || profileCardsOptionalInfo || null;

    const profileCardsContent = hasProfileCards ? (
      <div className={styles.profileCardsContainer} data-testid="profileCardsContainer">
        {profCards}
      </div>
    ) : (
      <Content className={styles.secondary}>
        Your profile is complete! Don&apos;t forget: Keep your profile up-to-date to get matched
        with the best opportunities!
      </Content>
    );

    const rehostViewProfileCardsContent = (
      <div className={styles.profileCardsContainer} data-testid="profileCardsContainer">
        {rehostProfileCards.map((profileCard) => (
          <ProfileCard
            key={profileCard.id}
            {...profileCard}
            setBackLinkToHome={setBackLinkToHome}
          />
        ))}
      </div>
    );

    const itemOneSetting =
      showOne ||
      (hasProfileCards && !allOptional) ||
      (hasProfileCards && allOptional && showProfileCards);

    const profileCardsFinal = itemOneSetting
      ? rehostView
        ? rehostViewProfileCardsContent
        : profileCardsContent
      : null;

    const checklistArray = [
      {
        loading: profileCardsLoading,
        complete: !hasProfileCards || profileLocked || allOptional,
        open: hasProfileCards && !allOptional,
        idx: 1,
      },
      {
        loading: profileCardsLoading,
        complete: hiringStatus !== 'inactive',
        open: (!hasProfileCards || allOptional) && hiringStatus === 'inactive',
        idx: 2,
      },
      {
        loading: profileCardsLoading,
        complete: appStatus === 'approved',
        open:
          (!hasProfileCards || allOptional) &&
          hiringStatus !== 'inactive' &&
          appStatus !== 'approved',
        idx: 3,
      },
      {
        loading: profileCardsLoading || batchRequestLoading,
        open: false,
        complete:
          batchRequest.state &&
          (batchRequest.state === 'accepted' || batchRequest.state === 'active'),
        idx: 4,
      },
    ];

    // Item 4 -- Get Listed
    const getListedProps = {
      batchRequest,
      handleBatchRequest,
      handleBatchRerequest,
    };

    const showGetListedStates = ['active', 'accepted', 'pending'];

    const showGetListedContent =
      showGetListedStates.includes(batchRequest.state) ||
      ((!hasProfileCards || allOptional) &&
        hiringStatus !== 'inactive' &&
        appStatus === 'approved');

    const getListedContent = showGetListedContent ? <GetListed {...getListedProps} /> : null;

    const getListedFinal = showFour || showGetListedContent ? getListedContent : null;

    // Item 2 -- Hiring Status
    const hiringStatusInputProps = {
      required: true,
      source: HIRING_STATUS_SOURCE.slice(0, 2),
      name: 'hiringStatus',
      handleInputChange: handleCandidateInputChange,
      selectedValues: hiringStatus !== '' ? [hiringStatus] : [],
      blockStyle: true,
      hasLabel: true,
      disabled: profileLocked,
    };

    const statusString = STATUS_STRINGS[hiringStatus];

    const replaceSecondLine =
      batchRequest.state && (batchRequest.state === 'accepted' || batchRequest.state === 'active');

    const acceptedBatchContent =
      replaceSecondLine && batchRequest.state === 'accepted' ? (
        <Content key="acceptedBatch" className={styles.padded}>
          Since you are on deck to be listed for new job opportunities, you cannot change your
          status at this time. Contact{' '}
          <a href="mailto:support@rainmakers.co">support@rainmakers.co</a> for more help.
        </Content>
      ) : null;

    const activeBatchContent =
      replaceSecondLine && batchRequest.state === 'active' ? (
        <Content key="activeBatch" className={styles.padded}>
          Since you are currently listed for new job opportunities, you cannot change your status at
          this time. Contact <a href="mailto:support@rainmakers.co">support@rainmakers.co</a> for
          more help.
        </Content>
      ) : null;

    // If a profile is locked, need to change the message
    if (replaceSecondLine && statusString) {
      const newContent = acceptedBatchContent || activeBatchContent;
      statusString.splice(1, 1, newContent);
    }

    const showHiringStatusContent =
      hiringStatus === 'inactive' && (!hasProfileCards || allOptional);

    const hiringStatusContent = (
      <Content className={styles.secondary}>
        {statusString}
        <InputCheckboxMany {...hiringStatusInputProps} />
      </Content>
    );

    const hiringStatusFinal = showTwo || showHiringStatusContent ? hiringStatusContent : null;

    // Item 3 -- Get Approved
    const showGetApprovedContent = (!hasProfileCards || allOptional) && hiringStatus !== 'inactive';

    const getApprovedEnabledContent =
      appStatus !== 'approved' ? (
        <Content className={styles.secondary}>
          We review all profiles before listing them on the platform. Once approved, you&apos;ll be
          able to request to be shown to great companies!
        </Content>
      ) : (
        <Content className={styles.secondary}>
          You&apos;re approved! We like what we see and think that you have a great sales profile.
          Now you are ready to be shown to great companies!
        </Content>
      );

    const getApprovedContent = showGetApprovedContent ? (
      getApprovedEnabledContent
    ) : (
      <Content className={styles.secondary}>
        Your profile will be reviewed by someone on the Rainmakers team. If approved, you will
        receive an email and/or call from a Rainmakers Concierge.
      </Content>
    );

    const getApprovedFinal = showThree || showGetApprovedContent ? getApprovedContent : null;

    // Toggles
    const showOneToggleProps = {
      className: styles.toggle,
      onClick: () => {
        if (showProfileCards && allOptional && hasProfileCards) {
          candidateProfilePatchDone({
            candidateProfile: {
              showProfileCards: false,
            },
          });
        } else {
          this.setState({ showOne: !showOne });
        }
      },
    };

    const showTwoToggleProps = {
      className: styles.toggle,
      onClick: () => this.setState({ showTwo: !showTwo }),
    };

    const showThreeToggleProps = {
      className: styles.toggle,
      onClick: () => this.setState({ showThree: !showThree }),
    };

    const showFourToggleProps = {
      className: styles.toggle,
      onClick: () => this.setState({ showFour: !showFour }),
    };

    // Title Classes
    const titleOneClasses = classnames({
      [styles.danger]: checklistArray[0].open && !checklistArray[0].complete,
      [styles.normal]: !checklistArray[0].open && !checklistArray[0].complete,
      [styles.gray]: checklistArray[0].complete,
      [styles.optional]: checklistArray[0].optional,
    });
    const titleTwoClasses = classnames({
      [styles.danger]: checklistArray[1].open && !checklistArray[1].complete,
      [styles.normal]: !checklistArray[1].open && !checklistArray[1].complete,
      [styles.gray]: checklistArray[1].complete,
    });
    const titleThreeClasses = classnames({
      [styles.danger]: checklistArray[2].open && !checklistArray[2].complete,
      [styles.normal]: !checklistArray[2].open && !checklistArray[2].complete,
      [styles.gray]: checklistArray[2].complete,
    });
    const titleFourClasses = classnames({
      [styles.danger]: checklistArray[3].open && !checklistArray[3].complete,
      [styles.normal]: !checklistArray[3].open && !checklistArray[3].complete,
      [styles.gray]: checklistArray[3].complete,
    });

    const showGetListedCaret =
      (!hasProfileCards || allOptional) && hiringStatus !== 'inactive' && appStatus === 'approved';

    const getListedCaret = showGetListedCaret ? (
      <FontIcon iconName={showFour || showGetListedContent ? 'caret-up' : 'caret-down'} />
    ) : null;

    if (profileCardsLoading) {
      return (
        <div className={styles.checklistContainer} data-testid="candidateChecklist">
          <TableOverlay table="image" />
        </div>
      );
    }

    return (
      <div className={styles.checklistContainer} data-testid="candidateChecklist">
        <div key="item1" className={styles.checklistItemContainer}>
          <ChecklistIcon {...checklistArray[0]} />
          <Content className={styles.contentContainer}>
            <div {...showOneToggleProps}>
              <Bold className={titleOneClasses}>
                {rehostView ? 'Welcome back!' : 'Complete your profile'}
              </Bold>
              <FontIcon iconName={itemOneSetting ? 'caret-up' : 'caret-down'} />
            </div>
            {rehostView ? profileCardsRehostInfo : profileCardsCopy}
            {profileCardsFinal}
          </Content>
        </div>
        <div key="item2" className={styles.checklistItemContainer}>
          <ChecklistIcon {...checklistArray[1]} />
          <Content className={styles.contentContainer}>
            <div {...showTwoToggleProps}>
              <Bold className={titleTwoClasses}>Update your status</Bold>
              <FontIcon iconName={showTwo || showHiringStatusContent ? 'caret-up' : 'caret-down'} />
            </div>
            {hiringStatusFinal}
          </Content>
        </div>
        <div key="item3" className={styles.checklistItemContainer}>
          <ChecklistIcon {...checklistArray[2]} />
          <Content className={styles.contentContainer}>
            <div {...showThreeToggleProps}>
              <Bold className={titleThreeClasses}>Get approved</Bold>
              <FontIcon
                iconName={showThree || showGetApprovedContent ? 'caret-up' : 'caret-down'}
              />
            </div>
            {getApprovedFinal}
          </Content>
        </div>
        <div key="item4" className={styles.checklistItemContainer}>
          <ChecklistIcon {...checklistArray[3]} />
          <Content className={styles.contentContainer}>
            <div {...showFourToggleProps}>
              <Bold className={titleFourClasses}>Get listed</Bold>
              {getListedCaret}
            </div>
            {getListedFinal}
          </Content>
        </div>
      </div>
    );
  }
}

export default Checklist;
