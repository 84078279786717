import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import { CandidateAuthHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';
import { ONBOARDING_ROUTES_MAP } from 'connectors/Login/constants';

import Block from 'components/Block';
import Bolder from 'components/Bolder';

import Greeting from '../CandidateSettingsPages/CandidateSignupStatus/components/Greeting';

import Checklist from './components/Checklist';

import duck from './redux';

import styles from './CandidateHome.scss';

@CandidateAuthHOC()
class CandidateHome extends Component {
  componentDidMount() {
    const {
      actions: {
        candidateLoadStarted,
        profileCardsLoadStarted,
        batchRequestLoadStarted,
        resetBackLink,
        setDelayProfileCards,
      },
      backLink = {},
      delayProfileCards = false,
    } = this.props;

    // TODO Need to move this logic to ReadyContainer
    // if link matches current route, remove backlink
    if (backLink.link === '/candidate/home') {
      resetBackLink();
    }

    if (delayProfileCards) {
      setTimeout(() => {
        setDelayProfileCards({ delayProfileCards: false });
        profileCardsLoadStarted({ noLoader: true });
      }, 4000);
    } else {
      setTimeout(() => profileCardsLoadStarted({ noLoader: true }), 2000);
    }

    candidateLoadStarted();
    batchRequestLoadStarted();
  }

  handleCandidateInputChange = (event) => {
    const {
      target: { type, name, value, checked },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    const {
      actions: { candidatePatchStarted },
    } = this.props;

    candidatePatchStarted({ name, newValue });
  };

  render() {
    const {
      candidate,
      children,
      actions: {
        batchRequestPatchStarted,
        batchRequestRerequestStarted,
        candidateProfilePatchDone,
        setBackLink,
        profileCardsLoadDone,
      },
      profileCards,
      profileCardsLoading,
      rehostChecklist = {},
      batchRequest,
      batchRequestLoading,
      user: { currentProfileType, currentProfile: { onboardingStatus } = {} } = {},
      isApproved,
    } = this.props;

    if (currentProfileType && currentProfileType === 'Candidate') {
      if (!isApproved && onboardingStatus) {
        const { [onboardingStatus]: route } = ONBOARDING_ROUTES_MAP;

        if (route) {
          return <Redirect to={route} />;
        }
      }
    }

    const commonBlockProps = {
      addPadding: true,
      boxShadow: true,
      addWhiteBG: true,
      addMargin: true,
    };

    const welcomeBlockProps = {
      ...commonBlockProps,
      key: 'welcome',
    };

    const checklistTitle = <Bolder>CHECKLIST</Bolder>;

    const checklistBlockProps = {
      ...commonBlockProps,
      title: checklistTitle,
      key: 'checklist',
    };

    const greetingBlockProps = {
      ...commonBlockProps,
      key: 'greeting',
    };

    const checklistProps = {
      handleCandidateInputChange: this.handleCandidateInputChange,
      handleBatchRequest: () => batchRequestPatchStarted({ state: 'pending' }),
      handleBatchRerequest: batchRequestRerequestStarted,
      candidateProfilePatchDone,
      setBackLink,
      candidate,
      profileCards,
      profileCardsLoading,
      rehostChecklist,
      batchRequest,
      batchRequestLoading,
      actions: {
        profileCardsLoadDone,
      },
    };

    return (
      <>
        <Helmet key="helmet" title="Candidate Home" />
        <ReadyContainer key="readyContainer" className={styles.CandidateHome}>
          <LayoutWithoutSidebar
            content={
              <>
                <Block {...welcomeBlockProps}>
                  <Bolder className={styles.mainTitle}>Welcome to Rainmakers!</Bolder>
                </Block>
                <Block {...checklistBlockProps}>
                  <Checklist {...checklistProps} />
                </Block>
                <Block {...greetingBlockProps}>
                  <Greeting />
                </Block>
              </>
            }
          />
          {children}
        </ReadyContainer>
      </>
    );
  }
}

export default ConnectContainer(duck)(CandidateHome);
