import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import {
  segmentSetRecentCompCandidate,
  segmentSetDealInfoCandidate,
  segmentSetWhatSoldCandidate,
  segmentSetWhoSoldToCandidate,
} from 'segment/eventNames';

import ConnectContainer from 'containers/ConnectContainer';
import { CandidateAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';
import { promisePickerOptions } from 'connectors/Defaults/promises';

import { toggleArray } from 'utils/formUtils';

import FontIcon from 'components/FontIcon';
import Button from 'components/Button';
import Content from 'components/Content';
import Title from 'components/Title';
import Bolder from 'components/Bolder';

import duck from '../redux';
import styles from '../Onboarding.scss';

import ProgressNavSection from '../components/ProgressNavSection';

import RecentCompensation from './components/RecentCompensation';
import AEDealInfo from './components/AEDealInfo';
import CSMDealInfo from './components/CSMDealInfo';
import WhatSold from './components/WhatSold';
import WhoSoldTo from './components/WhoSoldTo';

const EVENTS = {
  oteCents: segmentSetRecentCompCandidate,
  totalCompCents: segmentSetRecentCompCandidate,
  leadsGeneratedPercentage: segmentSetDealInfoCandidate,
  renewalRetentionPercentage: segmentSetDealInfoCandidate,
  productTypes: segmentSetWhatSoldCandidate,
  soldToIndustries: segmentSetWhoSoldToCandidate,
};

const SALES_PERIODS_TYPES = {
  CSMSalesPeriod: CSMDealInfo,
  AESalesPeriod: AEDealInfo,
};

@CandidateAuthHOC()
class AddSalesInfo extends Component {
  constructor(props) {
    super(props);

    const { user: { currentProfile: { workHistories = [] } = {} } = {} } = props;

    this.state = {
      workHistory: workHistories[0] || {},
    };
  }

  componentDidMount() {
    const {
      actions: { serverErrorSet },
    } = this.props;

    serverErrorSet({ errors: [] });
    promisePickerOptions()
      .then((pickerOptions) => {
        this.setState({
          pickerOptions,
        });
      })
      .catch(handleError);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user: { currentProfile: { workHistories = [] } = {} } = {} } = nextProps;

    if (workHistories[0] && workHistories[0].salesPeriods) {
      this.setState({
        workHistory: workHistories[0],
      });
    }
  }

  trackEvent = ({ name: inputName }) => {
    const event = EVENTS[inputName];
    if (event) {
      const properties = {};

      const eventObject = { event, properties };

      trackEvent(eventObject);
    }
  };

  handleToggleArrayValue = (event) => {
    const {
      target: { name, value },
    } = event;

    const { workHistory } = this.state;

    const { [name]: arrayToUpdate } = workHistory;

    const newArray = toggleArray({ array: arrayToUpdate || [], value });

    this.setState((prevState) => ({
      workHistory: {
        ...prevState.workHistory,
        [name]: newArray,
      },
    }));

    const {
      actions: { addSalesInfoStarted },
    } = this.props;

    const salesData = {
      ...this.state,
      workHistory: {
        ...workHistory,
        [name]: newArray,
      },
    };

    addSalesInfoStarted({ type: 'workHistory', salesData, autosave: true });

    if (arrayToUpdate && arrayToUpdate.length === 0) {
      this.trackEvent({ name });
    }
  };

  handleToggleSoldToIndustryArrayValue = (event) => {
    const {
      target: { name, value },
    } = event;
    const { workHistory, workHistory: { soldToIndustries: arrayToUpdate = [] } = {} } = this.state;
    const {
      actions: { addSalesInfoStarted },
    } = this.props;

    let newValue = arrayToUpdate ? arrayToUpdate.slice() : [];

    if (arrayToUpdate && arrayToUpdate.includes(value)) {
      newValue = arrayToUpdate.filter((val) => val !== value);
    } else if (value === 'Many' || value === 'All') {
      newValue = [value];
    } else {
      newValue.push(value);
    }

    const salesData = {
      ...this.state,
      workHistory: {
        ...workHistory,
        [name]: newValue,
      },
    };

    addSalesInfoStarted({ type: 'workHistory', salesData, autosave: true });

    this.setState((prevState) => ({
      workHistory: {
        ...prevState.workHistory,
        [name]: newValue,
      },
    }));

    if (arrayToUpdate && arrayToUpdate.length === 0) {
      this.trackEvent({ name });
    }
  };

  handleSalesPeriodInputChange = ({ event, salesPeriodId }) => {
    const {
      target: { name, value },
    } = event;

    const { workHistory } = this.state;

    const { salesPeriods } = workHistory;

    const newSalesPeriods = salesPeriods.map((sp) => {
      if (sp.id === salesPeriodId) {
        return {
          ...sp,
          [name]: value,
        };
      }
      return sp;
    });

    this.setState((prevState) => ({
      workHistory: {
        ...prevState.workHistory,
        salesPeriods: newSalesPeriods,
      },
    }));

    const {
      actions: { addSalesInfoStarted },
    } = this.props;

    const salesData = {
      ...this.state,
      workHistory: {
        ...workHistory,
        salesPeriods: newSalesPeriods,
      },
    };

    addSalesInfoStarted({ type: 'salesPeriod', salesData, autosave: true });
  };

  handleSubmitForm = () => {
    const {
      actions: { addSalesInfoStarted },
    } = this.props;

    const salesData = { ...this.state };

    addSalesInfoStarted({ salesData });
  };

  render() {
    const { dispatch, serverErrors = [] } = this.props;

    const { workHistory, pickerOptions } = this.state;

    const {
      company,
      soldToCompanies = [],
      soldToCompanySizes = [],
      soldToDepartments = [],
      soldToIndustries = [],
      salesPeriods = [],
      productTypes = [],
      position,
    } = workHistory || {};

    const { name: companyName } = company || {};

    const {
      department: deptSource = [],
      size: sizeSource = [],
      industry: industrySource = [],
    } = pickerOptions || {};

    const serverErrorMessage = serverErrors.length > 0 ? serverErrors[0] : null;

    const serverErrorContent = serverErrorMessage ? (
      <div className={styles.errorMessage}>{serverErrorMessage}</div>
    ) : null;

    const titleProps = {
      key: 'title',
      string: 'Add your most recent info for',
    };

    const validField = (value) => value !== null && value !== undefined && value !== '';

    if (salesPeriods) {
      const {
        dealsAverageCents: dealsAverage = null,
        saleCycleDays: saleCycleDays = null,
        quotaAmountCents: quotaAmount = null,
        oteCents: currentOTE = null,
        salaryCents: currentSalary = null,
        totalCompCents: totalComp = null,
        hadQuota: hadQuota = null,
        renewalRetentionPercentage = null,
      } = salesPeriods[0] || {};

      const spType = salesPeriods[0] ? salesPeriods[0].type : null;

      const DealInfoComponent = spType ? SALES_PERIODS_TYPES[spType] : null;

      const hasCurrentComp =
        (validField(currentOTE) || validField(totalComp)) && validField(currentSalary);

      const hasCSMQuota = hadQuota ? validField(quotaAmount) : hadQuota === false;

      const hasDealInfo =
        spType === 'CSMSalesPeriod'
          ? validField(renewalRetentionPercentage) && hasCSMQuota
          : validField(dealsAverage) && validField(saleCycleDays) && validField(quotaAmount);

      const hasProductTypes = productTypes && productTypes.length > 0;

      const hasSoldTo =
        soldToDepartments &&
        soldToDepartments.length > 0 &&
        soldToCompanySizes &&
        soldToCompanySizes.length > 0 &&
        soldToIndustries &&
        soldToIndustries.length > 0;

      const isValid = hasCurrentComp && hasDealInfo && hasProductTypes && hasSoldTo;

      const prevButtonProps = {
        circle: true,
        onClick: () => dispatch(push('/create-profile')),
        className: styles.actionButton,
      };

      const nextButtonProps = {
        circle: true,
        active: isValid,
        onClick: this.handleSubmitForm,
        className: styles.actionButton,
        disabled: !isValid,
      };

      const currentRole = (
        <div className={styles.currentRole}>
          {position} @ <Bolder>{companyName}</Bolder>
        </div>
      );

      const dealInfoProps = {
        trackEvent: this.trackEvent,
        key: 'dealInfo',
        salesPeriod: salesPeriods[0],
        handleSalesPeriodInputChange: this.handleSalesPeriodInputChange,
      };

      const whatSoldProps = {
        key: 'whatSold',
        productTypes,
        handleToggleArrayValue: this.handleToggleArrayValue,
        maxValue: 5,
      };

      const whoSoldToProps = {
        key: 'whoSoldTo',
        soldToCompanies,
        soldToCompanySizes,
        soldToDepartments: soldToDepartments || [],
        soldToIndustries: soldToIndustries || [],
        deptSource,
        sizeSource,
        industrySource,
        handleToggleSoldToIndustryArrayValue: this.handleToggleSoldToIndustryArrayValue,
        handleToggleArrayValue: this.handleToggleArrayValue,
        maxCompaniesValue: 5,
        maxDepartmentsValue: 3,
        maxIndustriesValue: 3,
      };

      const recentCompensationProps = {
        trackEvent: this.trackEvent,
        key: 'recentComp',
        salesPeriod: salesPeriods[0],
        handleSalesPeriodInputChange: this.handleSalesPeriodInputChange,
      };

      const mainContent =
        salesPeriods && salesPeriods.length === 0 ? (
          <Content className={styles.contentContainer}>
            No work history added, please return to the previous page and add a work history.
          </Content>
        ) : (
          <React.Fragment>
            <Title {...titleProps} />
            {serverErrorContent}
            <Content key="content" className={styles.contentContainer}>
              {currentRole}
            </Content>
            <RecentCompensation {...recentCompensationProps} />
            <DealInfoComponent {...dealInfoProps} />
            <WhatSold {...whatSoldProps} />
            <WhoSoldTo {...whoSoldToProps} />
          </React.Fragment>
        );

      return (
        <React.Fragment>
          <Helmet key="helmet" title="Add Sales Info" />

          <ReadyContainer key="AddSalesInfo" className={styles.CandidateRegistration}>
            <div className={styles.prevButtonContainer}>
              <Button {...prevButtonProps} data-testid="prevButton">
                <FontIcon iconName="caret-left" />
              </Button>
            </div>

            <div className={styles.mainContainer}>
              <ProgressNavSection numFilled={3} />
              {mainContent}
            </div>

            <div className={styles.nextButtonContainer}>
              <Button {...nextButtonProps} data-testid="nextButton">
                <FontIcon iconName="caret-right" />
              </Button>
            </div>
          </ReadyContainer>
        </React.Fragment>
      );
    }

    const prevButtonProps = {
      circle: true,
      onClick: () => dispatch(push('/create-profile')),
      className: styles.actionButton,
    };

    const nextButtonProps = {
      circle: true,
      active: false,
      onClick: this.handleSubmitForm,
      className: styles.actionButton,
      disabled: true,
    };

    return (
      <React.Fragment>
        <Helmet key="helmet" title="Add Sales Info" />

        <ReadyContainer key="AddSalesInfo" className={styles.CandidateRegistration}>
          <div className={styles.prevButtonContainer}>
            <Button {...prevButtonProps} data-testid="prevButton">
              <FontIcon iconName="caret-left" />
            </Button>
          </div>

          <div className={styles.mainContainer}>
            <ProgressNavSection />

            <Content className={styles.contentContainer}>
              No work history added, please return to the previous page and add a work history.
            </Content>
          </div>

          <div className={styles.nextButtonContainer}>
            <Button {...nextButtonProps} data-testid="nextButton">
              <FontIcon className={styles.largeIcon} iconName="checkmark" />
            </Button>
          </div>
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(AddSalesInfo);
