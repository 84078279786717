import React, { useState, useEffect, useRef } from 'react';
import isEmpty from 'lodash.isempty';
import classnames from 'classnames';
import VimeoPlayer from '@vimeo/player';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import {
  segmentCandidateProfileViewEmployee,
  segmentCandidateProfileViewCandidate,
  segmentCandidateProfileViewLoggedOut,
  segmentProfileVideoWatched,
} from 'segment/eventNames';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import RequestIntroModal from 'connectors/DialogModals/RequestIntroModal';

import RequestConfirmationModal from 'pages/EmployeePortal/CandidateSearchPage/components/RequestConfirmationModal';

import { promiseGetRequisitionCandidates } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import Button from 'components/Button';
import DialogueModal from 'components/DialogueModal';
import FontIcon from 'components/FontIcon';

import { usePrevious } from 'hooks';

import duck from '../redux';

import Header from './components/ProfileHeader';
import Attributes from './components/ProfileAttributes';
import Sidebar from './components/ProfileSidebar';
import Summary from './components/ProfileSummary';
import RecentSalesInfo from './components/ProfileRecentSalesInfo';
import ProfileSales from './components/ProfileSales';
import Recruitment from './components/ProfileRecruitment';
import Education from './components/ProfileEducation';
import QAndA from './components/ProfileQAndA';
import Awards from './components/ProfileAwards';

import styles from './CandidateProfile.scss';

const CandidateProfile = (props) => {
  const prevCandidateId = usePrevious(props.candidateProfile.id);
  const player = useRef();

  const [state, setState] = useState({
    noReqCand: null,
    doneLoading: false,
    showTheater: false,
    showRequestIntroModal: false,
    requestSent: false,
  });

  const isInsideModal = !!props.modal?.key;

  useEffect(() => {
    trackCandidateProfileViewEvent(props);

    const {
      actions: { pickerOptionsLoadStarted },
      candidateProfile: { hide, id: candidateId } = {},
      isEmployee,
      loggedInSearchEmployerId,
      isAdmin,
    } = props;

    pickerOptionsLoadStarted();
    if (!hide) {
      if (isEmployee) {
        promiseGetRequisitionCandidates({ candidateId })
          .then((reqCandidates) => onRequisitionCandidatesFulfilled({ reqCandidates, candidateId }))
          .catch((error) => onRequisitionCandidatesFailed({ error, candidateId }));
      } else if (isAdmin && loggedInSearchEmployerId) {
        promiseGetRequisitionCandidates({
          candidateId,
          employerId: loggedInSearchEmployerId,
        })
          .then((reqCandidates) => onRequisitionCandidatesFulfilled({ reqCandidates, candidateId }))
          .catch((error) => onRequisitionCandidatesFailed({ error, candidateId }));
      } else {
        setState((prevState) => ({ ...prevState, doneLoading: true }));
      }
    } else {
      setState((prevState) => ({ ...prevState, doneLoading: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currCandidateId = prevCandidateId;

    const {
      candidateProfile: { id: candidateId, hide },
      isEmployee,
      isAdmin,
      loggedInSearchEmployerId,
      needReload,
    } = props;

    if (currCandidateId !== candidateId) {
      trackCandidateProfileViewEvent(props);
      setState((prevState) => ({
        ...prevState,
        doneLoading: false,
        requestSent: false,
        noReqCand: null,
      }));
    }

    if (!hide || needReload) {
      if (isEmployee) {
        promiseGetRequisitionCandidates({ candidateId })
          .then((reqCandidates) => onRequisitionCandidatesFulfilled({ reqCandidates, candidateId }))
          .catch((error) => onRequisitionCandidatesFailed({ error, candidateId }));
      } else if (isAdmin && loggedInSearchEmployerId) {
        promiseGetRequisitionCandidates({
          candidateId,
          employerId: loggedInSearchEmployerId,
        })
          .then((reqCandidates) => onRequisitionCandidatesFulfilled({ reqCandidates, candidateId }))
          .catch((error) => onRequisitionCandidatesFailed({ error, candidateId }));
      } else {
        setState((prevState) => ({ ...prevState, doneLoading: true }));
      }
    } else {
      setState((prevState) => ({ ...prevState, noReqCand: null, doneLoading: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.candidateProfile.id]);

  useEffect(() => {
    const { showTheater } = state;
    const { candidateProfile: { vimeoVideo: { vimeoResponse } = {} } = {} } = props;
    const { link } = vimeoResponse || {};

    if (link && player.current && showTheater && VimeoPlayer) {
      new VimeoPlayer(player.current, { url: link });
    }
  });

  const onRequisitionCandidatesFulfilled = ({ reqCandidates, candidateId }) => {
    const { candidateProfile: { id: currCandidateId } = {} } = props;

    if (currCandidateId === candidateId) {
      setState((prevState) => ({
        ...prevState,
        noReqCand: reqCandidates.length === 0,
        doneLoading: true,
      }));
    }
  };

  const onRequisitionCandidatesFailed = ({ error, candidateId }) => {
    handleError(error);
    const { candidateProfile: { id: currCandidateId } = {} } = props;

    if (currCandidateId === candidateId) {
      setState((prevState) => ({ ...prevState, noReqCand: true, doneLoading: true }));
    }
  };

  const handleRequestConfirmationCloseClick = ({ hasError }) => {
    const {
      actions: { requestSentDone },
      handleGetCandidate,
    } = props;

    requestSentDone({
      firstName: '',
      lastName: '',
      errorMessage: '',
      show: false,
    });

    setState((prevState) => ({ ...prevState, requestSent: !hasError }));

    handleGetCandidate({ showLoader: false });
  };

  const trackProfileVideoWatched = () => {
    const {
      candidateProfile: { firstName, lastName, fullName, id: candidateId } = {},
      parentView,
      user: { currentProfile: { employer: { name: employerName } = {} } = {} } = {},
    } = props;

    const properties = {
      firstName,
      lastName,
      fullName,
      candidateId,
      viewedFromPage: parentView,
      employerName,
    };

    const event = segmentProfileVideoWatched;

    const eventObject = { event, properties };

    trackEvent(eventObject);
  };

  const trackCandidateProfileViewEvent = (props) => {
    const {
      isAdmin,
      isEmployee,
      isCandidate,
      candidateProfile: { firstName, lastName, fullName, id: candidateId },
      parentView,
      searchParams = {},
    } = props;

    if (isAdmin) {
      return;
    }

    const candidate = {
      firstName,
      lastName,
      fullName,
      candidateId,
      viewedFromPage: parentView,
    };

    if (isEmployee) {
      const event = segmentCandidateProfileViewEmployee;

      if (parentView === 'featured') {
        const { trackProperties = {}, keywords = [] } = searchParams || {};

        const properties = {
          ...candidate,
          ...trackProperties,
          keywords,
        };

        const eventObject = { event, properties };

        return trackEvent(eventObject);
      }

      const properties = { ...candidate };

      const eventObject = { event, properties };

      return trackEvent(eventObject);
    }

    if (isCandidate) {
      const event = segmentCandidateProfileViewCandidate;

      const properties = { ...candidate };

      const eventObject = { event, properties };

      return trackEvent(eventObject);
    }

    const event = segmentCandidateProfileViewLoggedOut;

    const properties = { ...candidate };

    const eventObject = { event, properties };

    return trackEvent(eventObject);
  };

  const {
    actions: { employeePatchStarted, messageCreateStarted, requisitionCandidatesCreateStarted },
    candidateProfile: candidate,
    candidateProfileReqs = [],
    className,
    handleBookmarkCandidate,
    handleHideSearchCandidate,
    handleMakeOffer,
    handlePass,
    handlePassFeaturedCandidate,
    handleRemoveBookmarkCandidate,
    handleUndoHideSearchCandidate,
    handleUndoPassFeaturedCandidate,
    hideNames,
    isAdmin,
    isCandidate,
    isCandidateLoading,
    isEmployee,
    loggedInSearchEmployerId,
    match: { params: { id } = {} } = {},
    messageGroup,
    modal,
    needReload,
    parentView,
    patchResource,
    pickerOptions = {},
    pickerOptions: { stage: stageOptions = [] } = {},
    requestedCandidates = [],
    requisitionCandidates,
    requestConfirmation: { errorMessage = '', show: showRequestConfirmation = false } = {},
    setScrollDivRef,
    tableStatus,
    useHideNames,
    useBlankHideNames,
    user: {
      currentProfile: {
        hideCandidatePopupSeen = true,
        employer: {
          greenhouseReady = false,
          enableMessaging = false,
          name: employerName,
          highlights: employerHighlights,
          sizeMax: employerSizeMax,
          sizeMin: employerSizeMin,
          stage: employerFunding,
        } = {},
      } = {},
    } = {},
  } = props;

  const { doneLoading, noReqCand, showRequestIntroModal, requestSent, showTheater } = state;

  const {
    bookmark,
    candidateEducations,
    candidateRequirement,
    candidateRequirement: { oteCents = '' } = {},
    candidateSuggestionId,
    closingExperienceMonths,
    firstName,
    hide = false,
    hideSearch = false,
    hired = false,
    hiringStatus,
    id: candidateId,
    lastName,
    latestWorkHistoryJsonapi: latestWorkHistory = {},
    managementExperience,
    outsideSalesExperience,
    passed = false,
    qAndA,
    rainmakersAdmin = {},
    recentGrad: recentGradValue,
    resume,
    salesExperienceMonths,
    socialMedia,
    soldCLevel,
    soldSaas,
    startupExperience,
    suggested,
    summary,
    vimeoVideo: { id: vimeoVideoId, videoStatus } = {},
    workHistories = [],
  } = candidate || {};

  const {
    data: {
      attributes: {
        salesPeriods: mainSalesPeriods,
        role: recentRole = null,
        soldToDepartments: recentDepartments = [],
        soldToCompanySizes: recentSizes = [],
        soldToIndustries: recentIndustries = [],
        soldToCompanies: recentSoldToCompanies = [],
        territories: recentTerritories = [],
        productTypes: recentProductTypes = [],
      } = {},
      relationships: { salesPeriods: { data: salesPeriodsObjects = [] } = {} } = {},
    } = {},
    included = [],
  } = latestWorkHistory || {};

  const salesPeriodsIds = salesPeriodsObjects.map((sp) => sp.id);

  const salesPeriods =
    mainSalesPeriods ||
    included.filter((item) => salesPeriodsIds.includes(item.id)).map((sp) => sp.attributes);

  const latestSalesPeriod = salesPeriods && salesPeriods.length > 0 ? salesPeriods[0] : {};

  const {
    quotaAmountCents: recentQuotaAmount = null,
    dealsAverageCents: recentDealAverage = null,
    saleCycleDays: recentSaleCycleDays = null,
    leadsGeneratedPercentage: recentLeadsGenPercentage = null,
    dealsVolumeCents: recentDealVolume = null,
    renewalRetentionPercentage: recentRenewalRetentionPercentage = null,
    companiesSupported: recentCompaniesSupported = null,
  } = latestSalesPeriod || {};

  const reqs = candidateProfileReqs.filter((req) => req.state === 'open');

  const requestIntroModalProps = {
    ...props,
    candidateId,
    employerName,
    employerHighlights,
    employerFunding,
    employerSizeMin,
    employerSizeMax,
    greenhouseReady,
    enableMessaging,
    firstName,
    handleRequestIntroCloseClick: () =>
      setState((prevState) => ({ ...prevState, showRequestIntroModal: false })),
    hideNames,
    isAdmin,
    lastName,
    loggedInEmployerId: loggedInSearchEmployerId,
    messageCreateStarted,
    messageGroup,
    oteCents,
    parentView,
    rainmakersAdmin,
    reqs,
    requisitionCandidatesCreateStarted,
    stageOptions,
  };

  const profileHeaderProps = {
    candidate,
    currentPosition: workHistories[0],
    socialMedia,
    resume,
    hasResume: resume !== '/resumes/original/missing.png',
    hiringStatus,
    videoLink: videoStatus === 'available' ? `/intro-video/${vimeoVideoId}/` : '',
    handleShowTheater: () => {
      if (!isAdmin) {
        trackProfileVideoWatched();
      }
      setState((prevState) => ({ ...prevState, showTheater: true }));
    },
    alwaysUseHideName: requestedCandidates.includes(candidateId),
    useHideNames,
    useBlankHideNames,
    id,
  };

  const recentGrad = recentGradValue && (recentRole === 'Non-Sales' || workHistories.length === 0);

  const profileAttributesProps = {
    recentGrad,
    recentRole,
    salesExperienceMonths,
    closingExperienceMonths,
    soldSaas,
    soldCLevel,
    outsideSalesExperience,
    startupExperience,
    managementExperience,
  };

  const profileSidebarProps = {
    bookmark,
    candidateId,
    candidateSuggestionId,
    doneLoading,
    firstName,
    lastName,
    employeePatchStarted,
    handleMakeOffer,
    handlePass,
    handleHideSearchCandidate,
    handlePassFeaturedCandidate,
    handleUndoHideSearchCandidate,
    handleUndoPassFeaturedCandidate,
    handleBookmarkCandidate,
    handleRemoveBookmarkCandidate,
    handleRequestIntroClick: () =>
      setState((prevState) => ({ ...prevState, showRequestIntroModal: true })),
    hideCandidatePopupSeen,
    hired,
    hide,
    hideSearch,
    isAdmin,
    isCandidate,
    isCandidateLoading,
    isEmployee,
    isInsideModal,
    loggedInEmployerId: loggedInSearchEmployerId,
    needReload,
    needIntroButton: noReqCand,
    parentView,
    passed,
    patchResource,
    requestSent,
    requisitionCandidates,
    suggested,
    tableStatus,
  };

  const profileSummaryProps = {
    summary,
    recentRole,
    recentGrad,
    salesExperienceMonths,
    awards: <Awards qAndA={qAndA} />,
    attributes: <Attributes {...profileAttributesProps} />,
  };

  const profileRecentSalesInfoProps = {
    recentCompaniesSupported,
    recentDealVolume,
    recentQuotaAmount,
    recentDealAverage,
    recentSaleCycleDays,
    recentLeadsGenPercentage,
    recentRenewalRetentionPercentage,
    recentSoldToCompanies,
    recentTerritories,
    recentProductTypes,
    recentDepartments,
    recentSizes,
    recentIndustries,
    recentRole,
  };

  const profileRecruitmentProps = {
    candidateRequirement,
    pickerOptions,
  };

  const requestConfirmationModalProps = {
    handleRequestConfirmationCloseClick,
    errorMessage,
    firstName,
    lastName,
    hideNames,
  };

  const mainClassnames = classnames(
    {
      [styles.viewCandidate]: isEmpty(modal),
      [styles.viewCandidateModal]: !isEmpty(modal),
    },
    className
  );

  return (
    <div className={mainClassnames} data-testid="CandidateProfile">
      <div ref={setScrollDivRef} className={styles.scrollDiv}>
        <Sidebar {...profileSidebarProps} />

        <div className={styles.contentContainer}>
          <Header {...profileHeaderProps} />
          <Summary {...profileSummaryProps} />

          {recentGrad ? (
            <>
              <RecentSalesInfo {...profileRecentSalesInfoProps} />
              <Education candidateEducations={candidateEducations} recentGrad={recentGrad} />
              <ProfileSales workHistories={workHistories} />
              <Recruitment {...profileRecruitmentProps} />
            </>
          ) : (
            <>
              <RecentSalesInfo {...profileRecentSalesInfoProps} />
              <Recruitment {...profileRecruitmentProps} />
              <ProfileSales workHistories={workHistories} />
              <Education candidateEducations={candidateEducations} recentGrad={recentGrad} />
            </>
          )}

          <QAndA qAndA={qAndA} />

          {showRequestIntroModal ? <RequestIntroModal {...requestIntroModalProps} /> : null}

          {showRequestConfirmation ? (
            <RequestConfirmationModal {...requestConfirmationModalProps} />
          ) : null}

          {showTheater ? (
            <DialogueModal className={styles.modal}>
              <div className={styles.theaterContainer}>
                <Button
                  onClick={() => setState((prevState) => ({ ...prevState, showTheater: false }))}
                  className={styles.closeTheater}
                >
                  <FontIcon iconName="close" />
                </Button>
                <div className={styles.player} ref={player} />
              </div>
            </DialogueModal>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ComponentErrorBoundaryHOC()(ConnectContainer(duck)(CandidateProfile));
