import React from 'react';

import Tag from 'components/Tag';
import AutocompleteServer from 'connectors/AutocompleteServer';
import { SelectedRate } from 'components/SelectedRate';

import styles from './WhatSold.scss';

const WhatSold = ({ productTypes, handleToggleArrayValue, maxValue = null }) => {
  const selectedRate = maxValue === null ? '' : ` (${productTypes.length}/${maxValue})`;
  const maxSelected = maxValue !== null && productTypes.length >= maxValue;

  const productTypesInputProps = {
    required: true,
    placeholder: 'Type and choose an option or add if not found',
    value: '',
    characterLimit: 100,
    size: 'full',
    name: 'productTypes',
    label: "Types of products you've sold/supported in this role",
    handleInputChange: (event) =>
      handleToggleArrayValue({
        ...event,
        target: {
          ...event.target,
          name: event.target.name,
          value: event.target.value.name,
        },
      }),
    handleOnBlur: handleToggleArrayValue,
    topLabel: true,
    autocompleteType: 'product_types',
    field: 'name',
    needReset: true,
    hasAddButton: true,
    disabled: maxSelected,
  };

  const productTypesSelected =
    productTypes.length > 0
      ? productTypes.map((type) => {
          const tagProps = {
            name: type,
            inputName: 'productTypes',
            onDeleteClick: (name, inputName) =>
              handleToggleArrayValue({
                target: {
                  value: name,
                  name: inputName,
                },
              }),
          };
          return <Tag key={type} {...tagProps} />;
        })
      : null;

  return (
    <div className={styles.whatSoldContainer} data-testid="WhatSold">
      <div className={styles.containerTitle}>WHAT YOU&apos;VE SOLD/SUPPORTED</div>

      <AutocompleteServer {...productTypesInputProps} />
      <SelectedRate selectedRate={selectedRate} />
      <div className={styles.autocompleteContainer}>{productTypesSelected}</div>
    </div>
  );
};

export default WhatSold;
