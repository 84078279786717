import React from 'react';

import DialogueModal from 'components/DialogueModal';
import Input from 'components/Input';
import Button from 'components/Button';

import styles from './TemplateNameModal.scss';

const TemplateNameModal = ({
  showPrompt,
  templateName,
  handleSaveTemplate,
  handleSaveTemplateNameCloseClick,
  handleSaveTemplateNameInputChange,
}) => {
  const saveTemplateNameInputProps = {
    handleInputChange: handleSaveTemplateNameInputChange,
    value: templateName || '',
    name: 'templateName',
    placeholder: 'Sales Executive Intro, e.g.',
    autoFocus: true,
    size: 'full',
    topLabel: true,
  };

  const closeButton = (
    <Button onClick={handleSaveTemplateNameCloseClick} quaternary>
      Cancel
    </Button>
  );

  const actionButton = (
    <Button onClick={handleSaveTemplate} primary>
      Save
    </Button>
  );

  const title = showPrompt === 'templateEditName' ? 'Rename this template' : 'Name this template';

  const input = (
    <div className={styles.saveSearchInput}>
      <Input {...saveTemplateNameInputProps} />
    </div>
  );

  return (
    <DialogueModal>
      <div className={styles.saveSearchModal}>
        <div className={styles.saveSearchTitle}>{title}</div>
        {input}
        <div className={styles.saveSearchButtons}>
          {closeButton}
          {actionButton}
        </div>
      </div>
    </DialogueModal>
  );
};

export default TemplateNameModal;
