import React, { Component } from 'react';

import Block from 'components/Block';
import Button from 'components/Button';
import FontIcon from 'components/FontIcon';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import SalesPeriodInputs from 'pages/CandidatePortal/CandidateEditorPages/EditSales/components/SalesPeriodInputs';
import CSMSalesPeriodInputs from 'pages/CandidatePortal/CandidateEditorPages/EditSales/components/CSMSalesPeriodInputs';

import styles from './CandidateEditorSales.scss';

const SALES_PERIODS_TYPES = {
  csmSalesPeriod: CSMSalesPeriodInputs,
  aeSalesPeriod: SalesPeriodInputs,
};

class CandidateEditorSales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
    };
  }

  render() {
    const {
      handleSalesPeriodInputChange,
      router,
      candidate: { id: candidateId },
      modal,
      actions: { showModal },
      workHistories: { allIds: workHistoriesAllIds = [], byId: workHistoriesById = {} } = {},
      companies: { byId: companiesById = {} } = {},
      saving,
    } = this.props;

    const { showAll } = this.state;

    const savingContent = saving ? (
      <div className={styles.autoSaving}>Saving...</div>
    ) : (
      <div className={styles.autoSaved}>Saved</div>
    );

    const saveContent = saving === undefined ? null : savingContent;

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const salesBlockProps = {
      largeTitleFont: true,
      noTitlePadding: true,
    };

    const workHistoryIds = workHistoriesAllIds.filter(
      (id) => workHistoriesById[id].attributes.candidateId === candidateId
    );

    const workHistories = workHistoryIds.map((id) => {
      const { [id]: wh = {} } = workHistoriesById;

      const { relationships: { company: { data: companyIds = [] } = {} } = {} } = wh || {};

      const { [companyIds[0]]: { attributes: companyAttributes = {} } = {} } = companiesById || {};

      return {
        ...wh,
        attributes: {
          ...wh.attributes,
          company: companyAttributes,
        },
      };
    });

    const realWorkHistories = workHistories.filter(
      (wh) => wh && wh.attributes.role && wh.attributes.role !== 'Non-Sales'
    );

    const allNonSales = workHistories.every(
      (wh) => !wh.attributes.role || wh.attributes.role === 'Non-Sales'
    );

    const workHistoriesToShow = showAll ? realWorkHistories : realWorkHistories.slice(0, 1);

    const inputContent =
      workHistoriesToShow.length > 0
        ? workHistoriesToShow.map((wh, idx) => {
            const {
              relationships: {
                salesPeriods: { data: salesPeriodsIds = [], type: salesPeriodsTypes = [] } = {},
              } = {},
            } = wh;

            const salesPeriodType = salesPeriodsTypes[0] || null;

            const SalesPeriodComponent = salesPeriodType
              ? SALES_PERIODS_TYPES[salesPeriodType]
              : null;

            const fullSalesPeriods = salesPeriodsIds.map((id, idx) => {
              const {
                [salesPeriodsTypes[idx]]: {
                  byId: { [id]: { attributes: salesPeriod = {} } = {} } = {},
                } = {},
              } = this.props;

              return salesPeriod;
            });

            const salesPeriodProps = {
              key: wh.id,
              workHistory: {
                ...wh,
                attributes: {
                  ...wh.attributes,
                  salesPeriods: fullSalesPeriods,
                },
              },
              handleSalesPeriodInputChange,
              workHistoryIdx: idx,
            };

            return SalesPeriodComponent ? <SalesPeriodComponent {...salesPeriodProps} /> : null;
          })
        : [];

    const openWorkHistories = () =>
      showModal({
        key: 'CandidateEditorModal',
        originalUrl: modal.originalUrl,
        parent: router.location.state.parent,
        route: `/toolbox/candidate/${candidateId}/edit-work`,
      });

    const addWorkProps = {
      tertiary: true,
      onClick: openWorkHistories,
    };
    const emptyBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: false,
      className: styles.salesBlockEmpty,
    };

    const showAllButtonProps = {
      primary: true,
      onClick: () => this.setState({ showAll: true }),
    };

    const showMoreIcon = 'caret-down';

    const showAllButtonContainer =
      realWorkHistories.length > 1 && !showAll ? (
        <div className={styles.buttonContainer}>
          <Button {...showAllButtonProps}>
            Show More of Your History{' '}
            <FontIcon className={styles.showMoreIcon} iconName={showMoreIcon} />
          </Button>
        </div>
      ) : null;

    const content =
      realWorkHistories.length > 0 ? (
        <div className={styles.EditorSales}>
          <Block {...titleBlockProps}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Sales Performance</div>
              <div className={styles.saveContent}>{saveContent}</div>
            </div>
          </Block>
          <div className={styles.scrollDiv}>
            <Block {...salesBlockProps}>
              <div className={styles.salesInputContainer}>{inputContent}</div>
            </Block>
            {showAllButtonContainer}
          </div>
        </div>
      ) : (
        <div className={styles.EditorSales}>
          <Block {...titleBlockProps}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Sales Performance</div>
            </div>
          </Block>
          <div className={styles.scrollDiv}>
            <Block {...emptyBlockProps}>
              <div className={styles.salesInputEmptyContainer}>
                <div className={styles.content}>
                  You need to add work history before adding sales data.
                </div>
                <Button {...addWorkProps}>Add Work History</Button>
              </div>
            </Block>
          </div>
        </div>
      );

    const allNonSalesContent = allNonSales ? (
      <div className={styles.EditorSales}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Sales Performance</div>
          </div>
        </Block>
        <div className={styles.scrollDiv}>
          <Block {...emptyBlockProps}>
            <div className={styles.salesInputEmptyContainer}>
              <div className={styles.content}>
                Looks like your work history doesn&apos;t include any sales roles, you can skip this
                section.
              </div>
            </div>
          </Block>
        </div>
      </div>
    ) : null;

    const layoutWithoutSidebarProps = {
      content: allNonSales ? allNonSalesContent : content,
    };

    return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
  }
}

export default CandidateEditorSales;
