import React from 'react';
import { Link } from 'react-router-dom';

import Bold from 'components/Bold';
import FontIcon from 'components/FontIcon';

import styles from './ProfileCard.scss';

const ProfileCard = ({
  card: { link, header, subheader, checkbox },
  onClick,
  setBackLinkToHome,
}) => {
  return (
    <div className={styles.profileCardContainer}>
      <Link
        to={link}
        onClick={() => {
          if (onClick) onClick();
          if (setBackLinkToHome) setBackLinkToHome();
        }}
        className={styles.profileCard}
      >
        <div className={styles.profileCardContent}>
          <Bold>{header}</Bold>
          {subheader && <div className={styles.optional}>{subheader}</div>}
        </div>
        <div className={styles.linkContent}>
          <FontIcon iconName="caret-right" />
        </div>
      </Link>
      {checkbox && <div className={styles.checkbox}>{checkbox}</div>}
    </div>
  );
};

export default ProfileCard;
