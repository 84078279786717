import { createStructuredSelector } from 'reselect';

import selectBackLink from 'connectors/User/redux/selectors/selectBackLink';
import selectDelayProfileCards from 'connectors/User/redux/selectors/selectDelayProfileCards';

import {
  selectIsAdmin,
  selectIsCandidate,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { getApprovedStatus } from 'shared/store/app/selectors';

import {
  selectCandidate,
  selectCandidateUser,
} from '../../../CandidateEditorPages/redux/selectors';

import selectProfileCards from './selectProfileCards';
import selectProfileCardsLoading from './selectProfileCardsLoading';
import selectRehostChecklist from './selectRehostChecklist';
import selectBatchRequest from './selectBatchRequest';
import selectBatchRequestLoading from './selectBatchRequestLoading';

const selector = createStructuredSelector({
  profileCards: selectProfileCards,
  profileCardsLoading: selectProfileCardsLoading,
  rehostChecklist: selectRehostChecklist,
  batchRequest: selectBatchRequest,
  batchRequestLoading: selectBatchRequestLoading,
  backLink: selectBackLink,
  logoutPending: selectLogoutPending,
  isApproved: getApprovedStatus,
  isAdmin: selectIsAdmin,
  isCandidate: selectIsCandidate,
  candidate: selectCandidate,
  user: selectCandidateUser,
  delayProfileCards: selectDelayProfileCards,
});

export {
  selector as default,
  selectProfileCards,
  selectProfileCardsLoading,
  selectRehostChecklist,
  selectBatchRequest,
  selectBatchRequestLoading,
};
