import React from 'react';
import classnames from 'classnames';

import InputCheckbox from 'components/InputCheckbox';
import Label from 'components/Label';
import { SelectedRate } from 'components/SelectedRate';

import styles from './InputCheckboxMany.scss';

const InputCheckboxMany = (props) => {
  const onSelect = (value) => {
    const { handleInputChange, name, disabled } = props;
    const event = {
      target: {
        name,
        value,
        type: 'select',
      },
    };

    return disabled ? null : handleInputChange(event);
  };

  const {
    source,
    label,
    selectedValues: values,
    required,
    blockStyle,
    flexFit,
    hasLabel,
    smallBlock,
    disabled,
    autoFocus,
    highlight,
    maxSelected = null,
  } = props;

  const selectedValues = values || [];

  const selectedRate =
    maxSelected === null ? '' : ` (${(selectedValues || []).length}/${maxSelected})`;

  const containerClasses = classnames(styles.container, {
    [styles.flexFitContainer]: flexFit,
  });

  const inputClasses = classnames(styles.inputStyle, {
    [styles.flexFit]: flexFit,
    [styles.focus]: highlight,
  });

  const labelClasses = classnames(styles.labelStyle);

  const makeLabelledCheckboxes = (option) => {
    return (
      <InputCheckbox
        key={option.value}
        name={option.value}
        label={option.label}
        checked={selectedValues.includes(option.value)}
        handleInputChange={() => onSelect(option.value)}
        checkboxMany={true}
        blockStyle={blockStyle}
        flexFit={flexFit}
        required={required}
        smallBlock={smallBlock}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    );
  };

  const makeCheckboxes = (option) => {
    return (
      <InputCheckbox
        key={option}
        name={option}
        label={option}
        checked={selectedValues.includes(option)}
        handleInputChange={() => {
          onSelect(option);
        }}
        checkboxMany={true}
        blockStyle={blockStyle}
        flexFit={flexFit}
        required={required}
        smallBlock={smallBlock}
        disabled={
          disabled ||
          (maxSelected && !selectedValues.includes(option) && selectedValues.length >= maxSelected)
        }
        autoFocus={autoFocus}
      />
    );
  };

  return (
    <div className={containerClasses}>
      {label ? (
        <div>
          <Label required={required} className={labelClasses}>
            {label}
          </Label>
          <div>
            <SelectedRate selectedRate={selectedRate} />
          </div>
        </div>
      ) : null}
      <div className={inputClasses}>
        {hasLabel ? source.map(makeLabelledCheckboxes) : source.map(makeCheckboxes)}
      </div>
    </div>
  );
};

export default InputCheckboxMany;
