import { call, select, put, take } from 'redux-saga/effects';

import { handleError } from 'utils/common';

import { selectUser } from 'connectors/User/redux/selectors';

import { profileCardsLoadDone } from '../creators';

import { PROFILE_CARDS_LOAD_STARTED } from '../actions';

import { promiseGetProfileCards, promiseGetRehostChecklist } from '../../promises';

const sagaProfileCardsLoadStarted = function* sagaProfileCardsLoadStarted() {
  while (true) {
    yield take(PROFILE_CARDS_LOAD_STARTED);

    const { currentProfile: { id: candidateId = null } = {} } = yield select(selectUser);

    if (candidateId) {
      try {
        const promiseParams = {
          candidateId,
        };

        const profileCards = yield call(promiseGetProfileCards, promiseParams);
        const rehostChecklist = yield call(promiseGetRehostChecklist, promiseParams);
        yield put(profileCardsLoadDone({ profileCards, rehostChecklist }));
      } catch (error) {
        handleError(error);
        yield put(profileCardsLoadDone({ profileCards: [] }));
      }
    }
  }
};

export default sagaProfileCardsLoadStarted;
