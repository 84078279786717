import React from 'react';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Content from 'components/Content';
import FormattedArray from 'components/FormattedArray';
import FormattedMoney from 'components/FormattedMoney';
import FormattedPercentage from 'components/FormattedPercentage';

import styles from './ProfileRecentSalesInfo.scss';

export const ProfileRecentSalesInfo = ({
  recentCompaniesSupported,
  recentQuotaAmount,
  recentDealAverage,
  recentDealVolume,
  recentSaleCycleDays,
  recentLeadsGenPercentage,
  recentRenewalRetentionPercentage,
  recentSoldToCompanies,
  recentTerritories,
  recentProductTypes,
  recentDepartments,
  recentSizes,
  recentIndustries,
  recentRole,
  recentGrad,
  isPrint,
}) => {
  const title = (
    <Content>
      <Bolder>RECENT SALES INFO</Bolder>
    </Content>
  );

  const commonBlockProps = {
    addWhiteBG: true,
    title,
    secondaryTitle: true,
    className: styles.marginBottom,
  };

  const isCSM = recentRole === 'Account Management (CSM/AM)';
  const isNonSales = recentRole === 'Non-Sales';

  const verbTo = isCSM ? 'supported' : 'sold to';
  const verbIn = isCSM ? 'supported' : 'sold in';
  const verb = isCSM ? 'supported' : 'sold';

  const soldToArray = [
    [`Products ${verb}`, recentProductTypes],
    [`Departments ${verbTo}`, recentDepartments],
    [`Industry types ${verbTo}`, recentIndustries],
    [`Company sizes ${verbTo}`, recentSizes],
    [`Companies ${verbTo}`, recentSoldToCompanies],
    [`Territories ${verbIn}`, recentTerritories],
  ];

  const soldToMapped = soldToArray.map((arr) =>
    arr[1] && arr[1].length > 0 ? (
      <div key={`${arr[0]}${arr[1]}`} className={styles.flexColumnList}>
        <Bolder>{arr[0]}</Bolder>
        <FormattedArray charMax={50} array={arr[1]} cutOff={isPrint ? 0 : 4} />
      </div>
    ) : null
  );

  const dealVolumeContent = recentDealVolume ? (
    <Content className={styles.salesInfoItem}>
      <Bolder className={styles.salesInfoAmount}>
        <FormattedMoney amount={recentDealVolume} />
      </Bolder>
      <Content className={styles.salesLabel}>Deal Volume</Content>
    </Content>
  ) : null;

  const quotaAmountContent = recentQuotaAmount ? (
    <Content className={styles.salesInfoItem}>
      <Bolder className={styles.salesInfoAmount}>
        <FormattedMoney amount={recentQuotaAmount} />
      </Bolder>
      <Content className={styles.salesLabel}>Quota</Content>
    </Content>
  ) : null;

  const dealAverageContent = recentDealAverage ? (
    <Content className={styles.salesInfoItem}>
      <Bolder className={styles.salesInfoAmount}>
        <FormattedMoney amount={recentDealAverage} />
      </Bolder>
      <Content className={styles.salesLabel}>Avg. Deal Size</Content>
    </Content>
  ) : null;

  const dealCycleContent = recentSaleCycleDays ? (
    <Content className={styles.salesInfoItem}>
      <Bolder className={styles.salesInfoAmount}>{`${recentSaleCycleDays} days`}</Bolder>
      <Content className={styles.salesLabel}>Deal Cycle</Content>
    </Content>
  ) : null;

  const outboundContent = recentLeadsGenPercentage ? (
    <Content className={styles.salesInfoItem}>
      <Bolder className={styles.salesInfoAmount}>
        <FormattedPercentage percent={recentLeadsGenPercentage} />
      </Bolder>
      <Content className={styles.salesLabel}>Outbound Sales</Content>
    </Content>
  ) : null;

  const retentionContent = recentRenewalRetentionPercentage ? (
    <Content className={styles.salesInfoItem}>
      <Bolder className={styles.salesInfoAmount}>
        <FormattedPercentage percent={recentRenewalRetentionPercentage} />
      </Bolder>
      <Content className={styles.salesLabel}>Renewal/Retention Rate</Content>
    </Content>
  ) : null;

  const companiesSupportedContent = recentCompaniesSupported ? (
    <Content className={styles.salesInfoItem}>
      <Bolder className={styles.salesInfoAmount}>{recentCompaniesSupported}</Bolder>
      <Content className={styles.salesLabel}>Companies Managed</Content>
    </Content>
  ) : null;

  const recentQuotaOrDealContent = quotaAmountContent || dealVolumeContent || null;

  const salesContent =
    !recentSaleCycleDays &&
    !recentLeadsGenPercentage &&
    !recentDealAverage &&
    !recentQuotaOrDealContent &&
    !recentRenewalRetentionPercentage &&
    !recentCompaniesSupported ? null : (
      <Content className={styles.flexRow}>
        {recentQuotaOrDealContent}
        {dealAverageContent}
        {dealCycleContent}
        {outboundContent}
        {retentionContent}
        {companiesSupportedContent}
      </Content>
    );

  const soldToContent = soldToMapped.some((arr) => arr) ? soldToMapped : null;

  return recentGrad || isNonSales || (!salesContent && !soldToContent) ? null : (
    <Block {...commonBlockProps} data-testid="ProfileRecentSalesInfo">
      <div className={styles.flexColumn}>{salesContent}</div>
      <div className={styles.flexRow}>{soldToContent}</div>
    </Block>
  );
};
export default ComponentErrorBoundaryHOC()(ProfileRecentSalesInfo);
