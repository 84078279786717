import React, { useState } from 'react';
import classnames from 'classnames';
import { push } from 'connected-react-router';
import dayjs from 'dayjs';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

import Button from 'components/Button';
import InputSelect from 'components/InputSelect';
import InputSelectManyValues from 'components/InputSelectManyValues';
import InputCheckboxMany from 'components/InputCheckboxMany';
import FromToYearMonth from 'components/FromToYearMonth';
import AutocompleteServer from 'connectors/AutocompleteServer';
import AutocompleteValueRow from 'components/AutocompleteValueRow';
import InputRichTextLabeled from 'components/InputRichTextLabeled';

import RoleChangeModal from '../RoleChangeModal';

import styles from './WorkInputs.scss';

const WorkInputs = (props) => {
  const [showRoleChangeModal, setShowRoleChangeModal] = useState(false);
  const [handleUpdateRole, setHandleUpdateRole] = useState(() => null);

  const {
    dispatch,
    hasRoleChangeModal = false,
    workInputsType = 'full',
    workHistory,
    idx,
    handleToggleEditingWork,
    handleCancelEditingWork,
    handleWorkHistoryDelete,
    handleWorkHistoryCompanyChange,
    handleWorkHistoryCompanyBlur,
    handleWorkHistoryInputChange,
    handleCurrentPositionInputChange,
    handleWorkHistoryDateInputChange,
    handleToggleSoldToIndustryArrayValue,
    handleToggleArrayValue,
    pickerOptions = {},
    autofocusFields = [],
  } = props;

  const {
    company,
    description = '',
    position: positionName,
    startDate,
    endDate,
    soldToCompanies = [],
    soldToCompanySizes = [],
    soldToIndustries = [],
    soldToDepartments = [],
    territories = [],
    productTypes = [],
    role = null,
  } = workHistory || {};

  const isCSM = role === 'Account Management (CSM/AM)';

  const { name: companyName, picture } = company || {};

  const { large: companyLogo } = picture || {};

  const picUrl = companyLogo === '' ? EmptyCompanyLogo : companyLogo;

  const {
    department: deptSource = [],
    size: sizeSource = [],
    industry: industrySource = [],
    role: roleSource = [],
  } = pickerOptions || {};

  const manage = isCSM ? '/support' : '';
  const managed = isCSM ? '/supported' : '';

  const startDateObject = startDate ? dayjs(startDate, 'YYYY-MM-DD').toDate() : new Date();
  const endDateObject = endDate ? dayjs(endDate, 'YYYY-MM-DD').toDate() : new Date();

  const isValid =
    workHistory.company &&
    workHistory.company.id &&
    workHistory.position &&
    workHistory.role &&
    startDate !== '' &&
    endDate !== '' &&
    startDateObject <= endDateObject &&
    endDateObject <= new Date();

  const basicInfoContainerClasses = classnames({
    [styles.basicInfoContainer]: workInputsType !== 'full',
    [styles.basicInfoContainerFull]: workInputsType === 'full',
  });

  const basicContainerClasses = classnames({
    [styles.basicContainer]: workInputsType !== 'full',
    [styles.basicContainerFull]: workInputsType === 'full',
  });

  return (
    <div key={idx} className={basicInfoContainerClasses}>
      <div className={basicContainerClasses}>
        <div className={styles.companyLogo}>
          <img className={styles.logo} src={picUrl} alt="" />
        </div>

        <div className={styles.basicInfoColumnInputs}>
          <AutocompleteServer
            placeholder={'Where did you work?'}
            size={'full'}
            name={'companyId'}
            value={companyName || ''}
            label={'Company Name'}
            handleInputChange={(event) => handleWorkHistoryCompanyChange({ event, idx })}
            handleOnBlur={(event) => handleWorkHistoryCompanyBlur({ event, idx })}
            hasLogo={true}
            required={true}
            autocompleteType={'companies'}
            field={'name'}
            autoFocus={autofocusFields.includes('companyName')}
            highlight={autofocusFields.includes('companyName')}
          />

          <AutocompleteServer
            placeholder={'Job Title'}
            size={'full'}
            name={'position'}
            label={'Position Title'}
            hasLabel={true}
            matchLabel={true}
            required={true}
            autocompleteType={'positions'}
            field={'position'}
            autoFocus={autofocusFields.includes('position')}
            highlight={autofocusFields.includes('position')}
            value={positionName || ''}
            handleInputChange={(event) =>
              handleWorkHistoryInputChange({
                event: {
                  target: {
                    name: 'position',
                    value: event.target.value.name,
                  },
                },
                idx,
              })
            }
            handleOnBlur={(event) =>
              handleWorkHistoryInputChange({
                event: {
                  target: {
                    name: 'position',
                    value: event.target.value,
                  },
                },
                idx,
              })
            }
          />

          <InputSelect
            label={'What role best describes your position?'}
            name={'role'}
            defaultLabel={'Role'}
            defaultIcon={'caret-down'}
            activeIcon={'caret-up'}
            required={true}
            source={roleSource.map((role) => ({ label: role, value: role }))}
            autoFocus={!role && autofocusFields.includes('role')}
            highlight={!role || (!role && autofocusFields.includes('role'))}
            handleInputChange={(event) => {
              if (role && hasRoleChangeModal) {
                setHandleUpdateRole(() => {
                  handleWorkHistoryInputChange({ event, idx });
                  setShowRoleChangeModal(false);
                });
                setShowRoleChangeModal(true);
              } else {
                handleWorkHistoryInputChange({ event, idx });
              }
            }}
            value={role || []}
          />

          <FromToYearMonth
            idx={idx}
            startDate={startDate}
            endDate={endDate}
            handleWorkHistoryDateInputChange={handleWorkHistoryDateInputChange}
            handleCurrentPositionInputChange={handleCurrentPositionInputChange}
            autofocusFields={autofocusFields}
          />

          <InputRichTextLabeled
            value={description}
            name="description"
            label="Description"
            autoFocus={autofocusFields.includes('description')}
            highlight={autofocusFields.includes('description')}
            handleInputChange={(event) => handleWorkHistoryInputChange({ event, idx })}
          />

          {workInputsType === 'full' ? (
            <div className={styles.basicInfoColumnInputs}>
              <AutocompleteValueRow
                placeholder={'Type and choose an option or add if not found'}
                value={''}
                size={'full'}
                name={'productTypes'}
                required={true}
                characterLimit={100}
                autocompleteType={'product_types'}
                field={'name'}
                needReset={true}
                hasAddButton={true}
                label={`What types of products did you sell${manage} in this role?`}
                maxSelected={5}
                handleInputChange={(event) =>
                  handleToggleArrayValue({
                    event: {
                      target: {
                        ...event.target,
                        name: event.target.name,
                        value: event.target.value.name,
                      },
                    },
                    idx,
                  })
                }
                onDeleteClick={(name, inputName) =>
                  handleToggleArrayValue({
                    event: {
                      target: {
                        value: name,
                        name: inputName,
                      },
                    },
                    idx,
                  })
                }
                handleOnBlur={(event) => handleToggleArrayValue({ event, idx })}
                autoFocus={
                  productTypes &&
                  productTypes.length === 0 &&
                  autofocusFields.includes('productTypes')
                }
                highlight={
                  productTypes &&
                  productTypes.length === 0 &&
                  autofocusFields.includes('productTypes')
                }
                selected={productTypes}
              />

              <AutocompleteValueRow
                placeholder={'Type and choose an option or add if not found'}
                size={'full'}
                name={'soldToCompanies'}
                characterLimit={100}
                hasLogo={true}
                autocompleteType={'companies'}
                field={'name'}
                needReset={true}
                hasAddButton={true}
                label={`Which companies did you sell to${manage}?`}
                value={''}
                maxSelected={5}
                handleInputChange={(event) =>
                  handleToggleArrayValue({
                    event: {
                      ...event,
                      target: {
                        ...event.target,
                        name: event.target.name,
                        value: event.target.value.name,
                      },
                    },
                    idx,
                  })
                }
                handleOnBlur={(event) =>
                  handleToggleArrayValue({
                    event,
                    idx,
                  })
                }
                onDeleteClick={(name, inputName) =>
                  handleToggleArrayValue({
                    event: {
                      target: {
                        value: name,
                        name: inputName,
                      },
                    },
                    idx,
                  })
                }
                autoFocus={
                  soldToCompanies &&
                  soldToCompanies.length === 0 &&
                  autofocusFields.includes('soldToCompanies')
                }
                highlight={
                  soldToCompanies &&
                  soldToCompanies.length === 0 &&
                  autofocusFields.includes('soldToCompanies')
                }
                selected={soldToCompanies}
              />

              <AutocompleteValueRow
                placeholder={'Type and choose an option or add if not found'}
                characterLimit={100}
                size={'full'}
                name={'territories'}
                autocompleteType={'territories'}
                field={'name'}
                needReset={true}
                hasAddButton={true}
                label={`Which territories did you sell to${manage}?`}
                value={''}
                maxSelected={3}
                handleInputChange={(event) =>
                  handleToggleArrayValue({
                    event: {
                      target: {
                        ...event.target,
                        name: event.target.name,
                        value: event.target.value.name,
                      },
                    },
                    idx,
                  })
                }
                handleOnBlur={(event) =>
                  handleToggleArrayValue({
                    event: {
                      target: {
                        ...event.target,
                        name: event.target.name,
                        value: event.target.value,
                      },
                    },
                    idx,
                  })
                }
                onDeleteClick={(name, inputName) =>
                  handleToggleArrayValue({
                    event: {
                      target: {
                        value: name,
                        name: inputName,
                      },
                    },
                    idx,
                  })
                }
                autoFocus={
                  territories && territories.length === 0 && autofocusFields.includes('territories')
                }
                highlight={
                  territories && territories.length === 0 && autofocusFields.includes('territories')
                }
                selected={territories}
              />

              <InputCheckboxMany
                required={true}
                label={`What size were the companies you sold to${managed}?`}
                source={sizeSource}
                name={'soldToCompanySizes'}
                handleInputChange={(event) => handleToggleArrayValue({ event, idx })}
                selectedValues={soldToCompanySizes || []}
                autoFocus={
                  soldToCompanySizes &&
                  soldToCompanySizes.length === 0 &&
                  autofocusFields.includes('soldToCompanySizes')
                }
                highlight={
                  soldToCompanySizes &&
                  soldToCompanySizes.length === 0 &&
                  autofocusFields.includes('soldToCompanySizes')
                }
              />

              <InputSelectManyValues
                size="full"
                name="soldToIndustries"
                defaultIcon="caret-down"
                defaultLabel="Select Industries"
                required={true}
                source={industrySource.map((ind) => ({
                  label: ind,
                  value: ind,
                }))}
                label={`What industries did you sell to${manage}?`}
                handleInputChange={(event) => handleToggleSoldToIndustryArrayValue({ event, idx })}
                onDeleteClick={(name, inputName) =>
                  handleToggleSoldToIndustryArrayValue({
                    event: {
                      target: {
                        value: name,
                        name: inputName,
                      },
                    },
                    idx,
                  })
                }
                selectedValues={soldToIndustries || []}
                maxSelected={3}
                autoFocus={
                  soldToIndustries &&
                  soldToIndustries.length === 0 &&
                  autofocusFields.includes('soldToIndustries')
                }
                highlight={
                  soldToIndustries &&
                  soldToIndustries.length === 0 &&
                  autofocusFields.includes('soldToIndustries')
                }
                values={soldToIndustries}
              />

              <InputCheckboxMany
                required={true}
                label={`What departments did you sell to${manage}?`}
                source={deptSource}
                name={'soldToDepartments'}
                handleInputChange={(event) => handleToggleArrayValue({ event, idx })}
                selectedValues={soldToDepartments || []}
                maxSelected={3}
                autoFocus={
                  soldToDepartments &&
                  soldToDepartments.length === 0 &&
                  autofocusFields.includes('soldToDepartments')
                }
                highlight={
                  soldToDepartments &&
                  soldToDepartments.length === 0 &&
                  autofocusFields.includes('soldToDepartments')
                }
              />
            </div>
          ) : null}

          <div className={styles.actionButtons}>
            {workHistory.id ? (
              <Button
                className={styles.deleteButton}
                warningTertiary={true}
                onClick={(event) => handleWorkHistoryDelete({ event, idx })}
              >
                Delete
              </Button>
            ) : null}
            <div className={styles.buttonsRight}>
              <Button quaternary={true} onClick={() => handleCancelEditingWork(idx)}>
                Cancel
              </Button>
              <Button
                primary={true}
                onClick={isValid ? () => handleToggleEditingWork(idx) : null}
                disabled={!isValid}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showRoleChangeModal ? (
        <RoleChangeModal
          handleUpdateRole={handleUpdateRole}
          handleCreateNewWorkHistory={() => {
            setShowRoleChangeModal(false);
            handleToggleEditingWork(idx);
            dispatch(push('/candidate/edit-work?model_index=-2'));
          }}
          handleCancel={() => setShowRoleChangeModal(false)}
        />
      ) : null}
    </div>
  );
};

export default WorkInputs;
