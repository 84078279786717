const options = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const SALES_EXPERIENCE_QUESTIONS = {
  'default': [
    {
      question: 'Do you have outside sales experience?',
      options,
      key: 'outside_sales_experience',
      camelKey: 'outsideSalesExperience',
      label: 'Outside sales experience',
    },
    {
      question: 'Have you sold SaaS products before?',
      options,
      key: 'sold_saas',
      camelKey: 'soldSaas',
      label: 'Sold SaaS',
    },
    {
      question: 'Have you sold to C-level executives before?',
      options,
      key: 'sold_c_level',
      camelKey: 'soldCLevel',
      label: 'Sold to C-level',
    },
    {
      question: 'Do you have startup experience?',
      options,
      key: 'startup_experience',
      camelKey: 'startupExperience',
      label: 'Startup experience',
    },
    {
      question: 'Do you have management experience?',
      options,
      key: 'management_experience',
      camelKey: 'managementExperience',
      label: 'Management experience',
    },
  ],
  'Account Management (CSM/AM)': [
    {
      question: 'Have you sold/supported SaaS products before?',
      options,
      key: 'sold_saas',
      camelKey: 'soldSaas',
      label: 'Sold/Supported SaaS',
    },
    {
      question: 'Do you have startup experience?',
      options,
      key: 'startup_experience',
      camelKey: 'startupExperience',
      label: 'Startup experience',
    },
  ],
  'Sales Executive (AE)': [
    {
      question: 'Do you have outside sales experience?',
      options,
      key: 'outside_sales_experience',
      camelKey: 'outsideSalesExperience',
      label: 'Outside sales experience',
    },
    {
      question: 'Have you sold SaaS products before?',
      options,
      key: 'sold_saas',
      camelKey: 'soldSaas',
      label: 'Sold SaaS',
    },
    {
      question: 'Have you sold to C-level executives before?',
      options,
      key: 'sold_c_level',
      camelKey: 'soldCLevel',
      label: 'Sold to C-level',
    },
    {
      question: 'Do you have startup experience?',
      options,
      key: 'startup_experience',
      camelKey: 'startupExperience',
      label: 'Startup experience',
    },
    {
      question: 'Do you have management experience?',
      options,
      key: 'management_experience',
      camelKey: 'managementExperience',
      label: 'Management experience',
    },
  ],
  'Other Sales Roles': [],
  'Non-Sales': [
    {
      question: 'Are you a recent graduate?',
      options,
      key: 'recent_grad',
      camelKey: 'recentGrad',
      label: 'Recent graduate',
    },
  ],
};

export { SALES_EXPERIENCE_QUESTIONS };
