import React, { useState, useRef, useCallback } from 'react';
import v4 from 'uuid';
import classnames from 'classnames';

import { useOnClickOutside } from 'hooks';
import { VisibleHOC } from 'containers/HOC';

import FontIcon from 'components/FontIcon';
import Label from 'components/Label';

import styles from './InputSelectMany.scss';

const InputSelectMany = ({
  handleInputChange,
  name,
  autoFocus,
  defaultIcon,
  defaultLabel,
  disabled,
  highlight,
  isVisible,
  label,
  noPadding,
  required,
  selectedValues = [],
  source,
  maxSelected, // max number of options that can be selected
}) => {
  const [active, setActive] = useState(false);
  const ref = useRef();

  useOnClickOutside(
    ref,
    useCallback(() => setActive(false), [])
  );

  const onClick = () => setActive((active) => !active);

  const onSelect = (newValue) => {
    if (active) {
      const event = {
        target: {
          name,
          value: newValue,
          type: 'select',
        },
      };

      handleInputChange(event);
    }
  };

  const selectClasses = classnames(styles.selectStyle, {
    [styles.noPadding]: noPadding,
  });

  const inputClasses = classnames(styles.inputStyle, {
    [styles.active]: active,
    [styles.focus]: highlight,
  });

  const labelClasses = classnames(styles.labelStyle);

  const optionsContainerClasses = classnames({
    [styles.OptionsContainer]: isVisible,
    [styles.OptionsContainerAbove]: !isVisible,
  });

  const maxAlreadySelected = maxSelected && (selectedValues || []).length >= maxSelected;

  const makeOptions = (option) => {
    // sometimes selectedValues is null and everything breaks so we default it to an empty array
    const actualSelectedValues = selectedValues || [];
    const isSelected = option.value.type
      ? actualSelectedValues.map((val) => val[val.type]).includes(option.value[option.value.type])
      : actualSelectedValues.includes(option.value);

    const optionDisabled = !isSelected && maxAlreadySelected;

    const optionClasses = classnames(styles.Option, {
      [styles.activeOption]: isSelected,
      [styles.disabledOption]: optionDisabled,
    });

    const optionProps = {
      className: optionClasses,
      key: v4(),
      onClick: disabled
        ? null
        : () => {
            if (optionDisabled) return;

            onSelect(option.value);
          },
      value: option.value,
    };

    const indentString =
      option.indent && option.indent > 0
        ? new Array(option.indent).fill(<span className={styles.indent}>&mdash;</span>)
        : null;

    return (
      <div {...optionProps}>
        {indentString}
        {option.label}
      </div>
    );
  };

  const contentProps = {
    onClick: (event) => event.stopPropagation(),
    className: optionsContainerClasses,
  };

  const defaultLabelClasses = classnames(styles.inPlaceDefaultLabel, {
    [styles.disabled]: disabled,
  });

  return (
    <button type="button" className={selectClasses} autoFocus={autoFocus} ref={ref}>
      {label ? (
        <Label required={required} className={labelClasses}>
          {label}
        </Label>
      ) : null}
      <div className={styles.relative}>
        <div className={inputClasses} onClick={onClick}>
          <div className={defaultLabelClasses}>{defaultLabel}</div>
          {defaultIcon ? <FontIcon iconName={defaultIcon} /> : null}
        </div>
        {active ? <div {...contentProps}>{source.map(makeOptions)}</div> : null}
      </div>
    </button>
  );
};

export default VisibleHOC(220)(InputSelectMany);
